import React, { useState } from "react";
import "./forgotpassword.scss";
import translate from "../../utils/locale/language";
import seedjlogo from "../../assets/images/seedjlogo.png";
import { validEmailRegex } from "../../utils/utils";

function Forgotpassword() {
  const [state, setState] = useState({ email: "", errors: { email: "" } });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    let errors = state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Please enter a valid email";
        break;
      default:
        break;
    }
    setError("");
  };

  const handleSubmit = async (event) => {};

  return (
    <div className="forgotpassword-container">
      <div className="seedjlogo">
        <img src={seedjlogo} alt="seedj" />
      </div>
      <div className="forgotpassword">
        <div>
          <p className="hello">{translate("hello")}</p>
          <p className="welcome">{translate("welcome")}</p>
        </div>
        <form>
          <h2 className="ForgotPassword">{translate("ForgotPassword")}</h2>

          <div className="form-group">
            <label>Enter your registered e-mail to get link</label>
            {state.errors.email.length > 0 && (
              <span className="error">{state.errors.email}</span>
            )}
            <input
              type="email"
              className="form-control"
              placeholder="Email-Address"
              name="email"
              onChange={handleChange}
            />
          </div>

          {error && error.length > 0 && (
            <span className="apierror">{error}</span>
          )}

          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-dark btn-lg btn-block resetpassword-btn"
          >
            {translate("sendlink")}
          </button>
        </form>
      </div>
    </div>
  );
}
export default Forgotpassword;
