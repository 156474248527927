import React from "react";
import "./App.scss";
import Routes from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <React.Fragment>
      <ToastContainer limit={1} />
      <Routes />
    </React.Fragment>
  );
}

export default App;
