import React from "react";
import translate from "../../utils/locale/language";
import Select from "react-dropdown-select";

function GenericSelect({
  title='',
  options,
  labelField,
  valueField,
  value,
  onSet,
  state,
  className,
  disabled = false,
}) {
  return (
    <div className="genretags ">
      {title !== "" && (
        <span className="genreimg">
          <p>{translate(title) + " "}</p>
        </span>
      )}
      <div className={`select-container dropdownselector ${className}`}>
        <Select
          options={options}
          labelField={labelField}
          valueField={valueField}
          values={state ? [state] : value ? [value] : []}
          onChange={(values) => {
            values.length > 0 && onSet(values[0]);
          }}
          className="ReactDropdownSelect"
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default GenericSelect;
