import React, { useEffect, useState, useRef } from "react";
import translate from "../../../utils/locale/language";
import "../uploadcontent/uploadcontent.scss";
import ContentlistTable from "./components/contentlist/contentlist";
import UploadSinglePath from "./components/uploadsinglepath/uploadsinglepath";
import LoadingOverlay from "react-loading-overlay";
import urls from "../../../utils/apis/services/apiurls.json";
import { getMethod } from "../../../utils/apis/services/api";

function Pathmanagment({ type }) {
  const section = useRef(translate(type ? type : "singlepath"));
  const [sectiontype, setSectionType] = useState(2);
  const [contentlist, setContentList] = useState([]);
  const [editdata, setEditData] = useState(null);
  const [loader, setLoader] = useState(false);

  const getContentList = async () => {
    let contentlist = await getMethod(
      type === "singlepath" ? urls.uploadsinglepath : null
    );
    setContentList(contentlist && contentlist.data ? contentlist.data : []);
  };

  useEffect(() => {
    getContentList();
  }, [sectiontype]);

  const edit = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.uploadsinglepath + id + "/");
    setEditData(result.data);
    setSectionType(1);
    setLoader(false);
  };

  const rendersection = () => {
    return <UploadSinglePath setSection={setSectionType} editdata={editdata} />;
  };

  return (
    <div className="uploadcontent">
      <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
        {section.current !== null && (
          <div className="selectedsection">
            {
              <div className="uploadcontentsection">
                <div className="pageheading">
                  <h2>
                    {" "}
                    {sectiontype === 1 && (
                      <p
                        onClick={() => {
                          setSectionType(2);
                          setEditData(null);
                        }}
                      >
                        {"<"}
                      </p>
                    )}{" "}
                    {section.current} list
                  </h2>
                  {sectiontype === 2 && (
                    <button
                      className="add_new_tag"
                      onClick={() => setSectionType(1)}
                    >
                      {translate("addnewsinglepath")}
                    </button>
                  )}
                </div>

                <div className="userlisttable">
                  {sectiontype === 2 ? (
                    <ContentlistTable
                      type={section.current}
                      data={contentlist}
                      contentlist={() => getContentList()}
                      edit={(data) => edit(data)}
                      setLoader={(val) => setLoader(val)}
                      setSection={() => setSectionType(2)}
                    />
                  ) : (
                    rendersection()
                  )}
                </div>
              </div>
            }
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
}

export default Pathmanagment;
