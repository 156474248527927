import React, { useRef, useState, useEffect } from "react";
import "../../uploadcontent/uploadcontent.scss";
import "./historytable.scss";
import GenericSelect from "../../../../components/design-system/select";
import moment from "moment";
import {
  getMethod,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../utils/apis/services/api";
import LoadingOverlay from "react-loading-overlay";
import translate from "../../../../utils/locale/language";
import urls from "../../../../utils/apis/services/apiurls.json";
import TxtArea from "../../../../components/design-system/txtArea";
import Modal from "../../../../components/design-system/modal/modal";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import { truncateDecimals } from "../../../../utils/utils";

const inizializeStatus = (status) => {
  let newStatusOptions = [...status];

  newStatusOptions.forEach((elem) => {
    if (elem.slug !== "PAID" && elem.slug !== "REJECTED") {
      elem.disabled = true;
    }
    return elem;
  });

  return newStatusOptions;
};

const HistoryTable = ({ sectionCB, historyData, tutorId }) => {
  const [status, setStatus] = useState(historyData[0].status);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [reason, setReason] = useState(null);
  const [showReasonArea, setShowReasonArea] = useState(false);
  const inputRef = useRef(null);
  const [statusList, setStatusList] = useState([]);

  const onShowArea = () => setShowReasonArea((prec) => !prec);

  const getStatusList = async () => {
    let statuslist = await getMethod(urls.getstatushistory);
    const newStatusOptions = inizializeStatus(
      statuslist && statuslist.data ? statuslist.data : []
    );
    setStatusList(newStatusOptions);
  };
  useEffect(() => {
    getStatusList();
  }, []);

  const onGoBack = () => {
    onShowArea();
    setReason(null);
    setStatus(historyData[0].status);
  };

  const onReason = (e) => {
    setReason(e.target.value);
  };

  const onStatus = (value) => {
    if (value.slug === "REJECTED") setShowReasonArea(true);
    if (value !== "REJECTED" && reason) setReason(null);
    setStatus(value);
  };

  const handleFile = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    const file = e.target.files[0];
    formData.append("file", file);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setFile(result.data.id);
    setFileName(file.name);
    setLoader(false);
  };

  const handleClickResource = () => {
    inputRef.current.click();
  };

  const onDeleteFile = () => {
    setFile(null);
    setFileName(null);
  };

  const onDownload = async (file) => {
    try {
      const response = await fetch(file);
      const blob = await response.blob();
      FileSaver.saveAs(blob, file);
      toast.success("Successfully downloaded");
    } catch (e) {
      toast.success("Error downloading file");
      console.error(e);
    }
  };

  const onEdit = async () => {
    setLoader(true);
    let body = {};

    if (status.slug === "REJECTED") {
      body = {
        status: status.slug,
        reason,
      };
    } else {
      body = { status: status.slug, payment_proof: file };
    }

    let result = await putMethodAuthenticated(
      urls.getalltutor + tutorId + "/history/" + historyData[0].id + "/",
      body
    );

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      sectionCB(2);
      setLoader(false);
    } else {
      setLoader(false);

      toast.error(result.data.Error);
    }
  };

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="pageheading">
        <h2>History</h2>{" "}
        <p
          onClick={() => {
            sectionCB(3);
          }}
        >
          {"< history list"}
        </p>
      </div>
      <div className="royalties-hystory-table royalties uploadcontent">
        <table>
          <thead>
          <tr>
            <th className="rowdata1_royalties history_row1">{"Period"}</th>
            <th className="rowdata2_royalties history_row2">{"SUB GEN / ROYALTIES"}</th>
            <th className="rowdata3_royalties history_row3">{"SALES / ROYALTIES"}</th>
            <th className="rowdata4_royalties history_row4">{"VIEWS / ROYALTIES"}</th>
            <th className="rowdata5_royalties history_row5">{"STATUS"}</th>
            <th className="rowdata6_royalties history_row6">{"INVOICE"}</th>
            <th className="rowdata7_royalties history_row7"/>
          </tr>
          </thead>
          <tbody>
          {historyData.map((item, index) => {
            return (
              <tr key={index}>
                <td className="rowdata1_royalties history_row1">
                  {" "}
                  {item.period}
                </td>
                <td className="rowdata2_royalties history_row2">
                  {item.subscription_count}/{truncateDecimals(item.subscription_amount, 2)}€
                </td>
                <td className="rowdata3_royalties history_row3">
                  {item.sales_count}/{truncateDecimals(item.sales_amount, 2)}€
                </td>
                <td className="rowdata4_royalties history_row4">
                  <GenericSelect
                    options={statusList}
                    labelField={"name"}
                    valueField={"slug"}
                    value={status}
                    onSet={onStatus}
                  />
                </td>
                <td className="rowdata5_royalties history_row5">
                  <GenericSelect
                    options={statusList}
                    labelField={"name"}
                    valueField={"slug"}
                    value={status}
                    onSet={onStatus}
                  />
                </td>
                <td className="rowdata6_royalties history_row6">
                  {item.invoice ? (
                    <button onClick={() => onDownload(item.invoice)}>
                      Download
                    </button>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className="rowdata7_royalties history_row7">
                  {fileName ? (
                    <div className="upload-info-file-container">
                      <p>file: {fileName}</p>
                      <span onClick={() => onDeleteFile()}>delete file</span>
                    </div>
                  ) : (
                    <div className="royalties-operation-container">
                      <button
                        onClick={() => onDownload(item.payment_proof)}
                        className={`${
                          !item.payment_proof || reason
                            ? "history-btn-disabled"
                            : ""
                        }`}
                      >
                        Download
                      </button>
                      <div className="upload-file">
                        <input
                          type="file"
                          name="file"
                          accept=".pdf,.png,.jpg, .xls, .csv"
                          onChange={handleFile}
                          ref={inputRef}
                          className="input-resource"
                        />
                        <button
                          className={`${
                            reason ? "history-btn-disabled" : ""
                          }`}
                          onClick={handleClickResource}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
        <button className="uploadbutton" onClick={onEdit}>
          {translate("update")}
        </button>

        <Modal open={showReasonArea}>
          <div className="history-reason-modal">
            <h2>Rejected reason</h2>
            <TxtArea
              placeholder={"write reason..."}
              name="reason"
              onChange={onReason}
              value={reason}
              rows={10}
              cols={50}
            />
            <div className="history-reason-oprations">
              <button className="back-btn" onClick={onGoBack}>
                go back
              </button>
              <button className="uploadbutton" onClick={onEdit}>
                confirm
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </LoadingOverlay>
  );
};

export default HistoryTable;
