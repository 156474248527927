import React, { useState } from "react";
import urls from "../../../../../utils/apis/services/apiurls.json";
import {
  deleteMethodAuthenticated,
  getMethod,
} from "../../../../../utils/apis/services/api";
import { toast } from "react-toastify";

function TableBundle({
  editboundle,
  data,
  setLoader,
  contentlist,
  type,
  setSection,
}) {
  const [hover, setHover] = useState(null);

  const deleteBoundle = async (id) => {
    setLoader(true);
    let result = await deleteMethodAuthenticated(urls.uploadboundle + id + "/");
    toast.success(result.data.message);
    contentlist();
    setLoader(false);
  };

  const hideCourse = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.uploadcontent + id + "/?action=hide");
    toast.success(result.data.message);
    setSection();
    setLoader(false);
  };

  return (
    <table className="contentlisttable">
      <thead>
        <tr>
          <th style={{ width: "25%" }}>{"Select All"}</th>
          <th style={{ width: "25%" }}>{type + " Name"}</th>
          <th style={{ width: "25%" }}>{"Sales"}</th>
          <th style={{ width: "25%", border: 0 }}>{"Amount "}</th>
        </tr>
      </thead>

      <tbody>
        {data.map((item) => {
          return (
            <tr key={item.id}>
              <td
                className="rowdata1_boundle"
                onClick={() => editboundle(item.id)}
              >
                <img src={item.course_image} alt="" />
              </td>
              <td
                className="rowdata2_boundle"
                onClick={() => editboundle(item.id)}
              >
                {item.name}
              </td>
              <td
                className="rowdata3_boundle"
                onClick={() => editboundle(item.id)}
              >
                {item.sales}
              </td>
              <td
                className="rowdata4_boundle"
                onMouseEnter={() => setHover(item.id)}
                onMouseLeave={() => setHover(null)}
              >
                {item.amount}
                <i className="far fa-ellipsis-h"></i>
                {hover === item.id && (
                  <ul className="hovercontents">
                    <li
                      className="menu-link"
                      onClick={() => hideCourse(item.id)}
                    >
                      Hide
                    </li>
                    <li
                      className="menu-link"
                      onClick={() => deleteBoundle(item.id)}
                    >
                      Delete
                    </li>
                  </ul>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TableBundle;
