import React, { useEffect } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import Navbar from "../../../components/navbar/navbar";
import SideNav from "../../../components/sidenav/sidenav";
import Panel from "../../../routes/Panel";
import { setLocale } from "../../../utils/locale/language";
import "./dashboard.scss";

function Dashboard() {
  const history = useHistory();

  useEffect(() => {
    let lang = window.localStorage.getItem("lang");
    setLocale(lang ? lang : "en");
  }, []);

  const changeLanguage = (lan) => {
    setLocale(lan);
    window.localStorage.setItem("lang", lan);
    history.push(history.location.pathname);
  };

  return (
    <Router>
      <div>
        <Navbar langchange={(val) => changeLanguage(val)} type="admin" />
        <div style={{ display: "flex", background: "#f5f5fb",minHeight:"100vh" }}>
          <SideNav menu={false} />
          <Panel />
        </div>
      </div>
    </Router>
  );
}

export default Dashboard;
