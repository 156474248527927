import React from 'react';
import { Switch, BrowserRouter as Router , useHistory} from 'react-router-dom';
import login from '../screen/login/login';
import PrivateRoute from '../utils/PrivateRoute';
import PublicRoute from '../utils/PublicRoute';
import Dashboard from '../screen/panel/dashboard/dashboard';
import Forgotpassword from '../screen/forgotpassword/forgotpassword';

export default function Routes() {
  const history = useHistory()
  
  return (
    <Router history={history}>
      <Switch >
        <PublicRoute exact path='/' component={login} />
        <PublicRoute exact path='/login' component={login} />
        <PrivateRoute  path="/dashboard" component={Dashboard} />
        {/* <PrivateRoute path="/userlist" component={Dashboard} />
        <PrivateRoute path="/royalties" component={Dashboard} /> */}
        <PrivateRoute path="/partner/tutors" component={Dashboard} />
        <PrivateRoute path="/partner/labelagency" component={Dashboard} />
        <PublicRoute path="/forgotpassword" component={Forgotpassword} />
        <PrivateRoute path="/uploadcontent/course" component={Dashboard} />
        <PrivateRoute path="/uploadcontent/boundle" component={Dashboard} />
        <PrivateRoute path="/uploadcontent/charts" component={Dashboard} />
        <PrivateRoute path="/uploadcontent/samplelibrary" component={Dashboard} />
        <PrivateRoute path="/uploadtags/djing" component={Dashboard} />
        <PrivateRoute path="/uploadtags/producing" component={Dashboard} />
        <PrivateRoute path="/uploadtags/music" component={Dashboard} />
        <PrivateRoute path="/pathmanagment/singlepath" component={Dashboard} />
        <PrivateRoute path="/heroesmanagment/heroes" component={Dashboard} />
        <PrivateRoute path="/contestsmanagment/contests" component={Dashboard} />
        <PrivateRoute path="/resetpassword" component={Dashboard} />
      </Switch>
    </Router>
  );
}