import React from 'react'
import { NavLink } from "react-router-dom";

function SubMenuNavLinks({ url, name, image }) {
  return (
    <>
      <li>
        <NavLink
          exact
          to={() => url}
          className="submenu-nav-link"
          activeClassName="submenu-link-active"
          style={{ color: "#858585" }}
        >
          <img src={image} alt={name} />
          {name}
        </NavLink>
      </li>
    </>
  );
}

export default SubMenuNavLinks;
