import React, { useState, useEffect } from "react";
import "./infocontest.scss";
import {
  deleteMethodAuthenticated,
  getMethod,
  putMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import Modal from "../../../../../components/design-system/modal/modal";
import TxtArea from "../../../../../components/design-system/txtArea";
import ContestRow from "./contestrow";

const InfoContest = ({ data, setSection }) => {
  const { data: contestsData, id: contestId } = data;
  const [statusList, setStatusList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [note, setNote] = useState({ txt: "", id: null });
  const [noteList, setNoteList] = useState([]);

  const getStatusContest = async () => {
    let statusList = await getMethod(urls.getstatuscontest);
    setStatusList(statusList && statusList.data ? statusList.data : []);
  };

  useEffect(() => {
    getStatusContest();
  }, []);

  const downloadFile = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    aTag.setAttribute("target", "_blank");
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  const onSendNote = async () => {
    setNoteList([...noteList, note]);

    const body = {
      backoffice_note: note.txt,
    };

    const result = await putMethodAuthenticated(
      urls.uploadcontests + contestId + "/applications/" + note.id + "/",
      body
    );

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success("Note updated!");
    } else {
      toast.error("Error updating note!");
    }

    setShowModal(false);
    setNote({ txt: "", id: null });
  };

  const onSetWinner = async (
    id,
    status,
    backoffice_status,
    backoffice_note
  ) => {
    let result = null;

    if (status === "winner") {
      result = await deleteMethodAuthenticated(
        urls.uploadcontests + contestId + "/applications/" + id + "/"
      );
    } else {
      const payload = {
        is_winner: true,
        backoffice_status: backoffice_status?.slug,
        backoffice_note: backoffice_note,
      };

      result = await putMethodAuthenticated(
        urls.uploadcontests + contestId + "/applications/" + id + "/",
        payload
      );
   
    }

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setSection();
    } else {
      toast.error(result.data.Error);
    }
  };

  return (
    <div className="info-contests-container">
      <div className="contests-info-partecipant">
        <p>Number partecipant: {contestsData[0].applicants_count}</p>
      </div>
      <table className="contentlisttable">
        <thead>
          <tr>
            <th className="rowdata1_contests_info">partecipant</th>
            <th className="rowdata2_contests_info">location</th>
            <th className="rowdata3_contests_info">file</th>
            <th className="rowdata4_contests_info">choose winner</th>
            <th className="rowdata5_contests_info">choose status</th>
            <th className="rowdata6_contests_info"> note</th>
            <th className="rowdata7_contests_info" style={{ border: 0 }}>
              status
            </th>
          </tr>
        </thead>

        <tbody>
          {contestsData[0].applicants.map((elem, index) => {
            return (
              <ContestRow
                key={elem.id}
                data={elem}
                setShowModal={setShowModal}
                downloadFile={downloadFile}
                statusList={statusList}
                onSetWinner={onSetWinner}
                setNote={setNote}
                noteList={noteList}
                contestId={contestId}
              />
            );
          })}
        </tbody>
      </table>
      <Modal open={showModal}>
        <div className="note-container ">
          <h2>Notes</h2>
          <TxtArea
            textareaClassName={"txt-area-contest-note"}
            name="note"
            onChange={(e) =>
              setNote((prec) => ({ ...prec, txt: e.target.value }))
            }
            value={note.txt}
          />
          <div className="btn-note-container">
            <button onClick={() => setShowModal(false)}>go back</button>
            <button onClick={onSendNote}>save</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InfoContest;
