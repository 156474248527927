import React from "react";
import { Switch, Route } from "react-router-dom";

import Uploadcontent from "../screen/panel/uploadcontent/uploadcontent";
import Uploadtags from "../screen/panel/uploadtags/uploadtags";
import UserList from "../screen/panel/userlist/userlist";
import Pathmanagment from "../screen/panel/pathmanagment/pathmanagment";
import url from "../utils/apis/services/apiurls.json";
import HeroesManagment from "../screen/panel/heroesmanagment/heroesmanagment";
import ContestManagment from "../screen/panel/contests/contestmanagment";
import Tutor from "../screen/panel/tutor/tutor";
import Partner from "../screen/panel/partner/partner";
import ResetPassword from "../screen/panel/reset-password/resetPassword";

export default function Panel() {
  const uploadTagsTable = [
    { name: "Name", style: "25%" },
    { name: "Courses & Boundles", style: "35%" },
    { name: "Show as a row", style: "20%" },
    { name: " ", style: "20%" }
  ]

  const uploadHeroesTable = [
    { name: "Hero title", style: "35%" },
    { name: " ", style: "35%" },
    { name: "Show in platform ", style: "30%" }
  ]

  const AdminPanel = [
    {
      path: "/uploadcontent/course",
      exact: true,
      render: () => <Uploadcontent type={"course"} />,
    },
    {
      path: "/uploadcontent/boundle",
      exact: true,
      render: () => <Uploadcontent type={"boundle"} />,
    },
    {
      path: "/uploadcontent/charts",
      exact: true,
      render: () => <Uploadcontent type={"charts"} />,
    },
    {
      path: "/uploadcontent/samplelibrary",
      exact: true,
      render: () => <Uploadcontent type={"samplelibrary"} />,
    },
    {
      path: "/uploadtags/djing",
      exact: true,
      render: () => (
        <Uploadtags
          type={"djing"}
          url={url.uploadtags + "?filtertype=djing"}
          name={"Djing"}
          textAdd={"addnewtag"}
          uploadTagsTable={uploadTagsTable}
        />
      ),
    },
    {
      path: "/uploadtags/producing",
      exact: true,
      render: () => (
        <Uploadtags
          type={"producing"}
          url={url.uploadtags + "?filtertype=producing"}
          name={"Producing"}
          textAdd={"addnewtag"}
          uploadTagsTable={uploadTagsTable}
        />
      ),
    },
    {
      path: "/uploadtags/music",
      exact: true,
      render: () => (
        <Uploadtags
          type={"music"}
          url={url.uploadtags + "?filtertype=music-business"}
          name={"Music"}
          textAdd={"addnewtag"}
          uploadTagsTable={uploadTagsTable}
        />
      ),
    },

    {
      path: "/pathmanagment/singlepath",
      exact: true,
      render: () => <Pathmanagment type={"singlepath"} />,
    },

    {
      path: "/heroesmanagment/heroes",
      exact: true,
      render: () => (
        <HeroesManagment
          type={"heroes"}
          url={""}
          textAdd={"addnewheroes"}
          uploadHeroesTable={uploadHeroesTable}
        />
      ),
    },
    {
      path: "/contestsmanagment/contests",
      exact: true,
      render: () => (
        <ContestManagment
          type={"contests"}
          url={""}
          textAdd={"addnewcontests"}
        />
      ),
    },

    {
      path: "/partner/tutors",
      exact: true,
      render: () => <Tutor />,
    },
    {
      path: "/partner/labelagency",
      exact: true,
      render: () => <Partner/>,
    },
    {
      path: "/resetpassword",
      exact: true,
      render: () => <ResetPassword/>,
    },
  ];

  return (
    <Switch>
      {AdminPanel.map((route, index) => (
        <Route key={index} path={route.path} exact render={route.render} />
      ))}
    </Switch>
  );
}
