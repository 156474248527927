import React from "react";
import "./contentlist.scss";
import { deleteMethodAuthenticated } from "../../../../../utils/apis/services/api";
import { toast } from "react-toastify";
import urls from "../../../../../utils/apis/services/apiurls.json";

function Contentlist({
  data,
  setLoader,
  contentlist,
  edit,
  getInfo,
}) {

  const deleteContests = async (id) => {
    setLoader(true);
    let result = await deleteMethodAuthenticated(
      urls.uploadcontests + id + "/"
    );
    toast.success(result.data.message);
    contentlist();
    setLoader(false);
  };

  return (
    <table className="contentlisttable">
      <thead>
        <tr>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "40%" }}>name</th>
          <th style={{ width: "40%", border: 0 }}>op</th>
        </tr>
      </thead>

      <tbody>
        {data.map(({ name, id, image }, index) => {
          return (
            <tr key={id}>
              <td className=" rowdata1_contests rowdata1">
                <img src={image} alt="" />
              </td>
              <td className=" rowdata2_contests rowdata2">{name}</td>
              <td className=" rowdata3_contests rowdata3">
                <button className="menu-link" onClick={() => edit(id)}>
                  Edit 
                </button>

                <button
                  className="menu-link"
                  onClick={() => deleteContests(id)}
                >
                  delete
                </button>
                <button className="menu-link" onClick={() => getInfo(id)}>
                  info
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Contentlist;
