import React, { useEffect, useState, useRef } from "react";
import "../uploadcontent/uploadcontent.scss";
import "./uploadtags.scss";
import translate from "../../../utils/locale/language";
import { getMethod } from "../../../utils/apis/services/api";
import LoadingOverlay from "react-loading-overlay";
import ContentlistTable from "./components/contentlist/contentlist";
import Uploadcontettags from "./components/uploaddjing/uploadcontenttags";
import Pagination from "../../../components/design-system/pagination";
import { useClientSidePagination } from "../../../hooks/useClientSidePagination";

function Uploadtags({ type, url, name, textAdd, uploadTagsTable }) {
  const section = useRef(translate(type));
  const [sectiontype, setSectionType] = useState(2);
  const [contentlist, setContentList] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [editdata, setEditData] = useState(null);
  const [loader, setLoader] = useState(false);

  const {
    pageIndex,
    setPageIndex,
    maxPageNumberLimit,
    minPageNumberLimit,
    setmaxPageNumberLimit,
    setminPageNumberLimit,
  } = useClientSidePagination(sectiontype);

  const getContentList = async () => {
    let contentlist = await getMethod(url + "&page=" + pageIndex);
    setContentList(
      contentlist && contentlist.data.results ? contentlist.data.results : []
    );
    setPageCount(
      contentlist && contentlist.data.page_count
        ? contentlist.data.page_count
        : null
    );
  };

  useEffect(() => {
    getContentList();
  }, [sectiontype, pageIndex]);

  const edit = (data) => {
    setLoader(true);
    setEditData(data);
    setSectionType(1);
    setLoader(false);
  };

  const rendersection = () => {
    return (
      <Uploadcontettags
        setSection={() => setSectionType(2)}
        editdata={editdata}
        setedit={() => setEditData(null)}
        type={name}
      />
    );
  };

  return (
    <div className="uploadcontent">
      <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
        {section.current !== null && (
          <div className="selectedsection">
            {
              <div className="uploadcontentsection">
                <div className="pageheading">
                  <h2>
                    {" "}
                    {sectiontype === 1 && (
                      <p onClick={() => setSectionType(2)}>{"<"}</p>
                    )}{" "}
                    {section.current} list
                  </h2>
                  {sectiontype === 2 && (
                    <button
                      className="add_new_tag"
                      onClick={() => setSectionType(1)}
                    >
                      {translate(textAdd)}
                    </button>
                  )}
                </div>

                <div className="userlisttable">
                  {sectiontype === 2 ? (
                    <>
                      <ContentlistTable
                        type={section.current}
                        data={contentlist}
                        contentlist={() => getContentList()}
                        edit={(data) => edit(data)}
                        setLoader={(val) => setLoader(val)}
                        setSection={() => setSectionType(2)}
                        uploadTagsTable={uploadTagsTable}
                      />
                      <Pagination
                        maxPageNumberLimit={maxPageNumberLimit}
                        minPageNumberLimit={minPageNumberLimit}
                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                        setminPageNumberLimit={setminPageNumberLimit}
                        onPageChange={setPageIndex}
                        pages={pageCount}
                        currentPage={pageIndex}
                        pageLimit={4}
                      />
                    </>
                  ) : (
                    rendersection()
                  )}
                </div>
              </div>
            }
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
}

export default Uploadtags;
