import React from "react";
import "./contentlist.scss";
import TableCourses from "../uploadcourses/tableCourses";
import TableBundle from "../uploadboundle/tableBundle";
import TableCharts from "../uploadchart/tableCharts";
import TableSamples from "../uploadsample/tableSamples";

function ContentlistTable({
  type,
  data,
  setLoader,
  editcourse,
  setSection,
  editboundle,
  contentlist,
  editChart,
  editSample
}) {
  return (
    <RenderSection
      type={type}
      data={data}
      editcourse={editcourse}
      setSection={setSection}
      setLoader={setLoader}
      contentlist={contentlist}
      editboundle={editboundle}
      editSample={editSample}
      editChart={editChart}
    />
  );
}
export default ContentlistTable;

const RenderSection = ({
  type,
  data,
  editcourse,
  setSection,
  setLoader,
  contentlist,
  editboundle,
  editChart,
  editSample
}) => {
  return (
    <>
      {type === "Courses" && (
        <TableCourses
          type={type}
          data={data}
          editcourse={editcourse}
          setSection={setSection}
          setLoader={setLoader}
          contentlist={contentlist}
        />
      )}
      {type === "Boundle" && (
        <TableBundle
          editboundle={editboundle}
          data={data}
          setLoader={setLoader}
          contentlist={contentlist}
          type={type}
          setSection={setSection}
        />
      )}
      {type === "Charts" && (
        <TableCharts
          data={data}
          setLoader={setLoader}
          contentlist={contentlist}
          type={type}
          editChart={editChart}
        />
      )}
      {type === "Sample Library" && (
        <TableSamples
          data={data}
          setLoader={setLoader}
          contentlist={contentlist}
          type={type}
          editSample={editSample}
        />
      )}
    </>
  );
};
