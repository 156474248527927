import React, { useState, useRef, useEffect } from "react";
import UploadHeroes from "./components/uploadheroes/uploadheroes";
import translate from "../../../utils/locale/language";
import { getMethod } from "../../../utils/apis/services/api";
import ContentlistTable from "./components/contentlist/contentlist";
import LoadingOverlay from "react-loading-overlay";
import urls from "../../../utils/apis/services/apiurls.json";

function HeroesManagment({ type,  textAdd, uploadHeroesTable }) {
  const section = useRef(translate(type));
  const [sectiontype, setSectionType] = useState(2);
  const [contentlist, setContentList] = useState([]);
  const [editdata, setEditData] = useState(null);
  const [loader, setLoader] = useState(false);

  const getContentList = async () => {
    let contentlist = await getMethod(urls.uploadhero);
  
    setContentList(contentlist.data || []);
  };

  const edit =async (data) => {
    
    setLoader(true);
    let contentlist = await getMethod(urls.uploadhero + data.id + "/");
    setEditData(contentlist.data)
    setSectionType(1);
    setLoader(false);
  };

  const rendersection = () => {
    return (
      <UploadHeroes
        setSection={() => setSectionType(2)}
        editdata={editdata}
        setedit={() => setEditData(null)}
      />
    );
  };

  useEffect(() => {
    getContentList();
  }, [sectiontype]);

  return (
    <div className="uploadcontent">
      <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
        {section.current !== null && (
          <div className="selectedsection">
            {
              <div className="uploadcontentsection">
                <div className="pageheading">
                  <h2>
                    {" "}
                    {sectiontype === 1 && (
                      <p onClick={() => setSectionType(2)}>{"<"}</p>
                    )}{" "}
                    {section.current} list
                  </h2>
                  {sectiontype === 2 && (
                    <button
                      className="add_new_tag"
                      onClick={() => setSectionType(1)}
                    >
                      {translate(textAdd)}
                    </button>
                  )}
                </div>
                <div className="userlisttable">
                  {sectiontype === 2 ? (
                    <ContentlistTable
                      type={section.current}
                      data={contentlist}
                      contentlist={() => getContentList()}
                      edit={(data) => edit(data)}
                      setLoader={(val) => setLoader(val)}
                      setSection={() => setSectionType(2)}
                      uploadHeroesTable={uploadHeroesTable}
                    />
                  ) : (
                    rendersection()
                  )}
                </div>
              </div>
            }
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
}

export default HeroesManagment;
