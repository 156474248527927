const Pagination = ({
  onPageChange,
  pages,
  currentPage,
  setmaxPageNumberLimit,
  setminPageNumberLimit,
  maxPageNumberLimit,
  minPageNumberLimit,
  pageLimit,
}) => {
  const onChange = (pageNumber) => () => onPageChange(pageNumber);

  const pagesData = [];
  for (let i = 1; i <= Math.ceil(pages); i++) {
    pagesData.push(i);
  }

  const setPrevMinMax = () => {
    setmaxPageNumberLimit((prev) => prev - pageLimit);
    setminPageNumberLimit((prev) => prev - pageLimit);
  };

  const setNextMinMax = () => {
    setmaxPageNumberLimit((prev) => prev + pageLimit);
    setminPageNumberLimit((prev) => prev + pageLimit);
  };

  const onPrevPage = () => {
    if ((currentPage - 1) % 4 === 0 && currentPage !== 1) {
      setPrevMinMax();
    }
    if (currentPage !== 1) onPageChange((prev) => prev - 1);
  };

  const onNextPage = () => {
    if (currentPage + 1 > maxPageNumberLimit) {
      setNextMinMax();
    }
    if (currentPage < pages) onPageChange((prev) => prev + 1);
  };

  const onPrevPages = () => {
    onPageChange(minPageNumberLimit);
    setPrevMinMax();
  };

  const onNextPages = () => {
    onPageChange(maxPageNumberLimit + 1);
    setNextMinMax();
  };

  const onNextMax = () => {
    onPageChange(pages);
    setmaxPageNumberLimit(pages);
    setminPageNumberLimit(pages - pageLimit);
  };

  const onNextFirstPage = () => {
    onPageChange(1);
    setmaxPageNumberLimit(4);
    setminPageNumberLimit(0);
  };

  const showFirstPageNumber = currentPage === pages && currentPage !== 1;
  const showNextDots = pages > pageLimit && currentPage < pages;

  return (
    <nav className="scrollbar-hide box-pagination">
      {currentPage !== 1 && (
        <button onClick={onPrevPage} className="box-item ">
          <span aria-hidden="true">«</span>
          <span className="sr-only">Previous</span>
        </button>
      )}
      {showFirstPageNumber && (
        <button onClick={onNextFirstPage} className=" box-item">
          <span className="sr-only">FirstPage</span>
          <span aria-hidden="true">1 </span>
        </button>
      )}
      {maxPageNumberLimit > pageLimit && (
        <button onClick={onPrevPages} className=" box-item">
          <span className="sr-only">Next</span>
          <span aria-hidden="true">...</span>
        </button>
      )}

      {pagesData.map((page) => {
        if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
          return (
            <button
              className={`box-pages ${
                page === currentPage && "box-current-page"
              }`}
              onClick={onChange(page)}
              aria-current="page"
              key={page}
            >
              {page}
            </button>
          );
        }
      })}
      {showNextDots && (
        <div className="box-next">
          <button onClick={onNextPages} className="box-item">
            <span className="sr-only">Next</span>
            <span aria-hidden="true">...</span>
          </button>

          <button onClick={onNextMax} className="box-item">
            <span className="sr-only">Next</span>
            <span aria-hidden="true">{pages} </span>
          </button>
        </div>
      )}
      {currentPage < pages && (
        <button onClick={onNextPage} className="  box-item ">
          <span className="sr-only">Next</span>
          <span aria-hidden="true">»</span>
        </button>
      )}
    </nav>
  );
};

export default Pagination;
