import React, { useState } from "react";
import moment from "moment";

import "./royalties.scss";
import {truncateDecimals} from "../../../utils/utils";

const RoyaltiesTable = ({ royalties, sectionCB, setHistoryData, type }) => {
  const onSetHiestory = (item) => () => {
    setHistoryData([item]);
    sectionCB(4);
  };



  return (
    <div className="royalties-table-section royalties uploadcontent">
      <div className="pageheading">
        <h2>{type} history</h2>{" "}
        <p
          onClick={() => {
            sectionCB(2);
          }}
        >
          {`${"< " + type.toLowerCase() + " list"}`}
        </p>
      </div>
      <table>
        <thead>
          <tr>
            <th className="rowdata1_royalties">{"PERIOD"}</th>
            <th className="rowdata2_royalties">{"SUB GEN / ROYALTIES"}</th>
            <th className="rowdata3_royalties">{"SALES / ROYALTIES"}</th>
            <th className="rowdata4_royalties">{"VIEWS / ROYALTIES"}</th>
            <th className="rowdata5_royalties">{"PAID / TOTAL"}</th>
            <th className="rowdata6_royalties" />
          </tr>
        </thead>
        <tbody>
          {royalties.map((item, index) => {
            const isInvoiceRequired = item.status.slug === "invoice-required";
            return (
              <tr key={item.id}>
                <td className="rowdata1_royalties">
                  {item.period}
                </td>
                <td className="rowdata2_royalties">
                  {item.subscription_count}/{truncateDecimals(item.subscription_amount,2)}€
                </td>
                <td className="rowdata3_royalties">
                  {item.sales_count}/{truncateDecimals(item.sales_amount,2)}€
                </td>
                <td className="rowdata4_royalties">
                  {item.view_count}/{truncateDecimals(item.view_amount,2)}€
                </td>
                <td className="rowdata5_royalties">{item.status.name}</td>
                <td className="rowdata6_royalties coldata6_royalties">
                  <button
                    onClick={onSetHiestory(item)}
                    className={isInvoiceRequired ? `history-btn-disabled` : ""}
                    disabled={isInvoiceRequired}
                  >
                    History
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RoyaltiesTable;
