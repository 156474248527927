import React, { useEffect, useState } from "react";
import { useClientSidePagination } from "../../../hooks/useClientSidePagination";
import urls from "../../../utils/apis/services/apiurls.json";
import { getMethod } from "../../../utils/apis/services/api";
import translate from "../../../utils/locale/language";
import Pagination from "../../../components/design-system/pagination";
import "../uploadcontent/uploadcontent.scss";
import "./partner.scss";
import LoadingOverlay from "react-loading-overlay";

const PartnerList = ({ EditUser, setSection, section, getRoyaltiesData }) => {
  const [partnerlist, setPartnerList] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [loader, setLoader] = useState(false);

  const {
    pageIndex,
    setPageIndex,
    maxPageNumberLimit,
    minPageNumberLimit,
    setmaxPageNumberLimit,
    setminPageNumberLimit,
  } = useClientSidePagination();

  const getPartnersList = async () => {
    setLoader(true);
    let partnerslist = await getMethod(
      urls.getallpartner + "?page=" + pageIndex
    );
    setPartnerList(partnerslist?.data?.results || []);
    setPageCount(partnerslist?.data?.page_count || null);
    setLoader(false);
  };

  useEffect(() => {
    getPartnersList();
  }, [section, pageIndex]);
  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="pageheading">
        <h2>{translate("partner")}</h2>{" "}
        <div className="mainbuttons">
          <button className="create" onClick={() => setSection(1)}>
            {translate("create")}
          </button>
          <button className="bulk" onClick={() => setSection(2)}>
            {translate("bulk")}
          </button>
        </div>
      </div>

      <table className="partner-table">
        <thead>
          <tr>
            <th style={{ width: "35%" }}>{"Partner name"}</th>
            <th style={{ width: "35%" }}>{"Type"}</th>
            <th style={{ width: "30%", border: 0 }}></th>
          </tr>
        </thead>

        <tbody>
          {partnerlist &&
            partnerlist.map((item) => {
              return (
                <tr key={item.id}>
                  <td
                    className="rowdata1 rowdata1_partner"
                    onClick={() => EditUser(item.id)}
                  >
                    <img src={item.user.profile_image} alt="" />
                    <p>{item.user.full_name}</p>
                  </td>
                  <td
                    className="rowdata2 rowdata2_partner"
                    onClick={() => EditUser(item.id)}
                  >
                    <p>{item?.user?.role?.name}</p>
                  </td>

                  <td className="rowdata4">
                    <button
                      className="btn-royalties"
                      onClick={() => getRoyaltiesData(item.id)}
                    >
                      royalties
                    </button>
                    <i
                      className="fas fa-trash-alt delete-partner"
                      style={{ float: "right" }}
                      onClick={() => {}}
                    ></i>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Pagination
        maxPageNumberLimit={maxPageNumberLimit}
        minPageNumberLimit={minPageNumberLimit}
        setmaxPageNumberLimit={setmaxPageNumberLimit}
        setminPageNumberLimit={setminPageNumberLimit}
        onPageChange={setPageIndex}
        pages={pageCount}
        currentPage={pageIndex}
        pageLimit={4}
      />
    </LoadingOverlay>
  );
};

export default PartnerList;
