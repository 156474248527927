import React, { useState, useEffect } from "react";
import "../../../uploadcontent/components/uploadcourses/uploadcourses.scss";
import translate from "../../../../../utils/locale/language";
import LoadingOverlay from "react-loading-overlay";
import {
  getMethod,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import Select from "react-dropdown-select";
import "./uploadcontenttags.scss";
import { toast } from "react-toastify";
import InputText from "../../../../../components/design-system/inputText";

function Uploaddjing(props) {
  const [state, setState] = useState({
    id: props.editdata ? props.editdata.id : null,
    isActive: props.editdata ? props.editdata.is_active : null,
    list: props.editdata ? props.editdata.list : null,
    name: props.editdata ? props.editdata.name : "", //tag_name
    filter_type: props.editdata ? props.editdata.slug : "",
  });

  const [loader, setLoader] = useState(false);
  const [row, setRow] = useState(state.isActive === true ? true : false);
  const [courses, setCourses] = useState([]);
  const [courseslist, setCoursesList] = useState([]);
  const getCourseList = async () => {
    let courselist = await getMethod(urls.allcourses);
    setCoursesList(courselist && courselist.data ? courselist.data : []);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getCourseList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let body = {
      filtertype:
        props.type === "Djing"
          ? "djing"
          : props.type === "Music"
          ? "music-business"
          : "producing",
      tag_name: state.name,
      activation: row,
      list: courses.map((item) => item.id),
    };

    let result = await postMethodAuthenticated(urls.uploadtags, body);
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      props.setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoader(true);
    let body = {
      tag_name: state.name,
      list:
        courses.length > 0
          ? courses.map((item) => item.id)
          : state.list.map((item) => item.id),
    };

    let result = await putMethodAuthenticated(
      urls.uploadtags + props.editdata.id + "/",
      body
    );
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      props.setedit();
      props.setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="uploadcourses">
        <h2 className="heading">{props.type}</h2>
        <form onSubmit={props.editdata ? handleUpdate : handleSubmit}>
          <div className="flexrows">
            <InputText
              title={"name"}
              value={state.name}
              onChange={handleChange}
              placeholder="Write the name of filter here..."
              name="name"
            />
          </div>
          <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("filtertype")}</label>
              <span className="divider"></span>
              <div className="dropdownselector">
                <p>{props.type}</p>
              </div>
            </div>
          </div>
          {!props.editdata && (
            <div className="flexrows">
              <div className="checkbox-container">
                <div className="checkbox-content">
                  <p>Show as a row ?</p>
                  <label className="container">
                    YES
                    <input
                      type="radio"
                      name="showAsARow"
                      value={false}
                      onChange={(e) => {
                        setRow(false);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container">
                    NO
                    <input
                      type="radio"
                      name="showAsARow"
                      value={false}
                      defaultChecked
                      onChange={(e) => {
                        setRow(false);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="addcourses genretags tags_boundles_courses_list">
            <span className="genreimg">
              <p>{translate("boundlesandcourses") + " "}</p>
            </span>
            <div className="dropdownselector">
              <Select
                multi
                options={courseslist}
                labelField={"name"}
                valueField={"id"}
                values={
                  props.editdata && props.editdata.list
                    ? props.editdata.list
                    : courses
                }
                onChange={(values) => setCourses(values)}
                className="ReactDropdownSelect"
              />
            </div>
          </div>

          <button className="uploadbutton" type="submit">
            {props.editdata ? translate("update") : translate("upload")}
          </button>
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default Uploaddjing;
