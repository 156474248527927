import React from "react";
import {
  deleteMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import "./contentlist.scss"


function ContentlistTable({
  data = [],
  contentlist,
  edit,
  setLoader,
  uploadHeroesTable,
}) {


  const deleteHeroes = async (id) => {
    setLoader(true);
    let result = await deleteMethodAuthenticated(urls.uploadhero + id + "/");
    toast.success(result.data.message);
    contentlist();
    setLoader(false);
  };


  return (
    <table className="contentlisttable">
      <thead>
        <tr>
          {uploadHeroesTable.map((elem, index) => (
            <th style={{ width: elem.style }} key={index}>
              {elem.name}
            </th>
          ))}
        </tr>
      </thead>

      <tbody className="">
        {data.length > 0 &&
          data.map((item) => {
            return (
              <tr key={item.id}>
              
                <td className="rowdata1">
                  {" "}
                  <p onClick={() => edit(item)}>{item.title}</p>
                </td>
                <td className="rowdata2">
                  <button className="menu-link" onClick={() => edit(item)}>
                    edit
                  </button>
                  <button
                    className="menu-link"
                    onClick={() => deleteHeroes(item.id)}
                  >
                    Delete
                  </button>
                </td>
                <td className="rowdata3">{item.is_active ? "Yes" : "No"}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

export default ContentlistTable;
