import React, { useEffect, useState } from "react";
import "./uploadsample.scss";
import translate from "../../../../../utils/locale/language";
import {
  getMethod,
  getMethodOpen,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import UploadImg from "../../../../../components/design-system/uploadImg";
import InputText from "../../../../../components/design-system/inputText";
import TxtArea from "../../../../../components/design-system/txtArea";
import GenericSelect from "../../../../../components/design-system/select";

function Uploadsample({ setSection, editdata, setedit }) {
  const [partnerlist, setPartnerList] = useState([]);
  const [selectedzipdata, setSelectedzipdata] = useState([]);
  const [zip_file, setZip_file] = useState(null);
  const [loader, setLoader] = useState(false);
  const [cover_image, setCover_image] = useState(null);
  const [coverimage, setCoverimage] = useState(null);

  const [state, setState] = useState({
    samplename: editdata ? editdata.name : "",
    description: editdata ? editdata.description : "",
    publicationdate: editdata ? editdata.publication_date : "",
    expirationdate: editdata ? editdata.expiration_date : "",
    genre: editdata ? editdata.genre : null,
    author: editdata ? editdata.author : null,
  });
  const [genrelist, setGenreList] = useState([]);
  const [genre, setGenre] = useState("");
  const [author, setAuthor] = useState("");
  const [is_trending, setisTrending] = useState(
    editdata ? editdata.is_trending : false
  );

  const getGenreList = async () => {
    let genrelist = await getMethodOpen(urls.genrelist);
    setGenreList(genrelist && genrelist.data ? genrelist.data : []);
  };

  const getPartnersList = async () => {
    let partnerslist = await getMethod(urls.getroles + "dj-partner");
    setPartnerList(partnerslist && partnerslist.data ? partnerslist.data : []);
  };

  const handleCoverImage = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setCover_image(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setCoverimage(result.data.id);
    setLoader(false);
  };

  const handleZipfile = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("zip", e.target.files[0]);
    setZip_file(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.sampleupload, formData);
    setSelectedzipdata(result.data);
    setLoader(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (state.genre === "" || author === "") {
      toast.error(translate("fillrequiredfields"));
    } else {
      setLoader(true);
      let body = {
        // "content_type": "sample",
        cover_image: coverimage,
        name: state.samplename,
        author: author.id,
        description: state.description,
        genre: genre.slug,
        publication_date: state.publicationdate,
        expiration_date: state.expirationdate,
        tracks: selectedzipdata.map((item) => item.id),
        is_trending: is_trending,
      };

      let result = await postMethodAuthenticated(urls.samplecreate, body);

      if (result.data && !result.data.ErrorCode && result.status === 200) {
        toast.success(result.data.message);
        setSection();
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(result.data.Error);
        setLoader(false);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    setLoader(true);
    let body = {
      name: state.samplename,
      author: author ? author.id : state.author.id,
      genre: genre ? genre.slug : state.genre.slug,
      description: state.description,
      publication_date: state.publicationdate,
      expiration_date: state.expirationdate,
      is_trending: is_trending,
    };

    if (coverimage) {
      body = { ...body, ["cover_image"]: coverimage };
    }

    let result = await putMethodAuthenticated(
      urls.sampleid + editdata.id + "/",
      body
    );

    if (result.status === 200) {
      toast.success(result.data.message);
      setedit();
      setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getGenreList();
    getPartnersList();
  }, []);

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="uploadsamples">
        <h2 className="heading">{translate("samplelibrary")}</h2>
        <form onSubmit={editdata ? handleUpdate : handleSubmit}>
          <div className="flexrows">
            <div className="coverimageselector">
              <label className="custom-file-upload">
                <input
                  disabled={editdata || false}
                  type="file"
                  onChange={handleZipfile}
                  accept="image"
                ></input>
                <p style={{ marginTop: "3vw" }}>
                  {zip_file || editdata ? "File uploaded" : "Upload Zip File"}
                </p>
              </label>
            </div>

            <UploadImg
              editdata={editdata ? editdata.cover_image : null}
              cover_image={cover_image}
              handleCoverImage={handleCoverImage}
            />
          </div>

          <div className="flexrows">
            <GenericSelect
              title={"author"}
              options={partnerlist}
              labelField={"full_name"}
              valueField={"id"}
              value={author}
              state={
                editdata
                  ? partnerlist.find((author) => author.id === state.author.id)
                  : null
              }
              onSet={setAuthor}
            />

            <GenericSelect
              title={"genre"}
              options={genrelist}
              labelField={"genre_name"}
              valueField={"slug"}
              value={genre}
              state={
                editdata
                  ? genrelist.find((genre) => genre.id === state.genre.id)
                  : null
              }
              onSet={setGenre}
            />

            <div
              className="custom-control custom-checkbox"
              style={{ width: "50%", margin: "2vw" }}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
                defaultChecked={is_trending}
                onChange={(e) => setisTrending(e.currentTarget.checked)}
              />
              <label
                className="custom-control-label rememberme"
                htmlFor="customCheck1"
              >
                {"Is Trending"}
              </label>
            </div>
          </div>

          <InputText
            title={"name"}
            type="text"
            placeholder="Enter the sample name..."
            name="samplename"
            value={state.samplename}
            onChange={handleChange}
          />

          <TxtArea
            title={"Description"}
            placeholder="Describe your video to let people know what it is…"
            value={state.description}
            name="description"
            onChange={handleChange}
          />

          <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("publicationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="publicationdate"
                value={state.publicationdate}
                onChange={handleChange}
                min={moment(new Date()).format("yyyy-MM-D")}
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("expirationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="expirationdate"
                value={state.expirationdate}
                onChange={handleChange}
                min={moment(state.publicationdate).format("yyyy-MM-D")}
                required
              ></input>
            </div>
          </div>

          {selectedzipdata.map((item, index) => {
            return (
              <div className="sampletrackscontainer">
                <div className="sampletrackstitle">
                  <p className="dashline">{"---" + index}</p>
                  <p style={{ display: "flex" }}>
                    {translate("filename")}:
                    <p className="detail">{item.track_file_display_name}</p>
                  </p>
                </div>

                <div className="flexrows">
                  <div className="textfieldedituser">
                    <label>{translate("name")}</label>
                    <span className="divider"></span>
                    <input
                      type="text"
                      name="name"
                      value={item.name}
                      disabled
                      onChange={handleChange}
                      required
                    ></input>
                  </div>

                  <div className="textfieldedituser" style={{ width: "35%" }}>
                    <label>{translate("bpm")}</label>
                    <span className="divider"></span>
                    <input
                      type="text"
                      name="bpm"
                      value={item.bmp}
                      disabled
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>

                <div className="flexrows">
                  <div className="flexrows" style={{ width: "100%" }}>
                    <div className="textfieldedituser">
                      <label>{translate("type")}</label>
                      <span className="divider"></span>
                      <input
                        type="text"
                        name="type"
                        value={item.track_type.name}
                        disabled
                        onChange={handleChange}
                      ></input>
                    </div>

                    <div className="textfieldedituser">
                      <label>{translate("structure")}</label>
                      <span className="divider"></span>
                      <input
                        type="text"
                        name="structure"
                        value={item.structure}
                        disabled
                        onChange={handleChange}
                      ></input>
                    </div>
                  </div>

                  <div className="textfieldedituser" style={{ width: "35%" }}>
                    <label>{translate("key")}</label>
                    <span className="divider"></span>
                    <input
                      type="text"
                      name="key"
                      value={item.key}
                      disabled
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
              </div>
            );
          })}

          <button className="uploadbutton" type="submit">
            {editdata ? translate("update") : translate("upload")}
          </button>
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default Uploadsample;
