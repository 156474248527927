import React, { useState, useEffect, useRef } from "react";
import translate from "../../../../../utils/locale/language";
import urls from "../../../../../utils/apis/services/apiurls.json";
import {
  getMethod,
  getMethodOpen,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import LoadingOverlay from "react-loading-overlay";
import "./uploadcontests.scss";
import { toast } from "react-toastify";
import UploadImg from "../../../../../components/design-system/uploadImg";
import InputText from "../../../../../components/design-system/inputText";
import Multiselect from "../../../../../components/design-system/multiselect";
import TxtArea from "../../../../../components/design-system/txtArea";
import GenericSelect from "../../../../../components/design-system/select";
import DynamicInputs from "./dynamicInputs";
import moment from "moment";

const setGenresData = (genre) => {
  let genreData = [];
  genre.forEach((elem) => {
    genreData.push({
      name: elem.genre_name,
      slug: elem.slug,
      id: elem.id,
    });
  });

  return genreData;
};

const UploadContests = ({ editdata, setSection }) => {
  const [state, setState] = useState({
    image: editdata ? editdata.image : null,
    name: editdata ? editdata.name : "",
    host: editdata ? editdata.host : null,
    description: editdata ? editdata.description : "",
    type: editdata ? editdata.type : "",
    genres: editdata ? editdata.genres : [],
    publication_date: editdata ? editdata.publication_date : "",
    deadline_date: editdata ? editdata.deadline_date : "",
    judging_start_date: editdata ? editdata.judging_start_date : "",
    judging_end_date: editdata ? editdata.judging_end_date : "",
    winner_announcement_date: editdata ? editdata.winner_announcement_date : "",
    category: editdata ? editdata.category : [],
    resources: editdata ? editdata.resources : null,
    rules: editdata ? editdata.rules : [],
    reward: editdata ? editdata.rewards : [],
    in_platform: editdata ? editdata.in_platform : false,
    level: editdata ? editdata.level : [],
    host_can_announce_winner: editdata
      ? editdata.host_can_announce_winner
      : false,
  });

  const inputRef = useRef(null);

  const [partnerslist, setPartnerList] = useState([]);
  const [coverimage, setCoverimg] = useState(null);
  const [cover_image, setCover_image] = useState(null);
  const [conteststypelist, setConteststypelist] = useState([]);
  const [conteststype, setConteststype] = useState("");
  const [categorylist, setCategoryList] = useState([]);
  const [genrelist, setGenreList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [levellist, setLevelList] = useState([]);

  const handleChange = (type) => (e) => {
    const { value } = e.target;
    if (type === "in_platform" || type === "host_can_announce_winner") {
      setState((prevState) => ({
        ...prevState,
        [type]: value === "true" ? true : false,
      }));
    } else
      setState((prevState) => ({
        ...prevState,
        [type]: value,
      }));
  };

  const handleSelect = (type) => (data) => {
    setState((prevState) => ({
      ...prevState,
      [type]: data,
    }));
  };

  const [loader, setLoader] = useState(false);

  const onDeleteFile = () => {
    setFile(null);
    setFileName(null);

    if (state.resources) {
      setState((prevState) => ({
        ...prevState,
        resources: null,
      }));
    }
  };

  const handleCoverImage = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setCover_image(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setCoverimg(result.data.id);
    setLoader(false);
  };

  const handleClickResource = () => {
    inputRef.current.click();
  };

  const handleFile = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    const file = e.target.files[0];
    formData.append("file", file);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setFile(result.data.id);
    setFileName(file.name);
    setLoader(false);
  };

  const getPartnersList = async () => {
    let partnerslist = await getMethod(urls.allpartners);
    setPartnerList(
      partnerslist && partnerslist.data
        ? partnerslist.data.sort((a, b) => (a.full_name < b.full_name ? -1 : 1))
        : []
    );
  };

  const getContestsType = async () => {
    let conteststype = await getMethod(urls.getconteststype);
    setConteststypelist(
      conteststype && conteststype.data ? conteststype.data : []
    );
  };

  const getLevelList = async () => {
    let levellist = await getMethod(urls.getpathlevel);
    setLevelList(levellist && levellist.data ? levellist.data : []);
  };

  const getGenreList = async () => {
    let genrelist = await getMethodOpen(urls.genrelist);
    const genresData = setGenresData(genrelist.data);
    setGenreList(genresData);
  };

  const getCatogeoryList = async () => {
    let catogeorylist = await getMethodOpen(urls.getcoursecategory);
    setCategoryList(
      catogeorylist && catogeorylist.data ? catogeorylist.data : []
    );
  };

  useEffect(() => {
    getPartnersList();
    getGenreList();
    getCatogeoryList();
    getLevelList();
    getContestsType();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let body = {
      image: coverimage,
      name: state.name,
      host: state.host.id,
      description: state.description,
      type: conteststype.slug,
      genres: state.genres.map((item) => item.slug),
      publication_date: state.publication_date,
      deadline_date: state.deadline_date,
      judging_start_date: state.judging_start_date,
      judging_end_date: state.judging_end_date,
      winner_announcement_date: state.winner_announcement_date,
      category: state.category.map((item) => item.slug),
      level: state.level.map((item) => item.slug),
      rules: state.rules,
      rewards: state.reward,
      in_platform: state.in_platform,
      host_can_announce_winner: state.host_can_announce_winner,
    };

    if (file) {
      body = { ...body, resources: file };
    }

    let result = await postMethodAuthenticated(urls.uploadcontests, body);
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setSection();
      setLoader(false);
    } else {
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let body = {
      host: state.host.id,
      type: conteststype !== "" ? conteststype.slug : state.type.slug,
      category: state.category.map((item) => item.slug),
      genres: state.genres.map((item) => item.slug),
      level: state.level.map((item) => item.slug),
      rules: state.rules,
      rewards: state.reward,
      in_platform: state.in_platform,
      publication_date: state.publication_date,
      deadline_date: state.deadline_date,
      judging_start_date: state.judging_start_date,
      judging_end_date: state.judging_end_date,
      winner_announcement_date: state.winner_announcement_date,
      description: state.description,
      name: state.name,
      host_can_announce_winner: state.host_can_announce_winner,
    };

    if (cover_image) {
      body = { ...body, image: coverimage };
    }

    if (file) {
      body = { ...body, resources: file };
    }

    let result = await putMethodAuthenticated(
      urls.uploadcontests + editdata.id + "/",
      body
    );
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="uploadcourses uploadcontests">
        <h2 className="heading">{translate("contests")}</h2>
        <form onSubmit={editdata ? handleUpdate : handleSubmit}>
          <UploadImg
            editdata={state.image}
            cover_image={cover_image}
            handleCoverImage={handleCoverImage}
          />

          <InputText
            title={"name"}
            placeholder={"Contest name..."}
            value={state.name}
            onChange={handleChange("name")}
            name="contestsname"
            type="text"
          />

          <GenericSelect
            title={"partners"}
            options={partnerslist}
            labelField={"full_name"}
            valueField={"id"}
            value={state.host}
            state={state.host}
            onSet={handleSelect("host")}
          />

          <TxtArea
            title={"Description"}
            placeholder={"Contest description..."}
            name={"description"}
            onChange={handleChange("description")}
            value={state.description}
          />

          <div className="flexrows">
            <div className="flexrows-content">
              <GenericSelect
                title={"conteststype"}
                options={conteststypelist}
                labelField={"name"}
                valueField={"id"}
                value={conteststype}
                state={state.type}
                onSet={setConteststype}
              />

              <Multiselect
                title={"genre"}
                options={genrelist}
                labelField={"name"}
                valueField={"id"}
                values={state.genres}
                state={state.genres}
                onChange={handleSelect("genres")}
              />
            </div>
          </div>

          <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("deadlineforapply")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="deadline_date"
                value={state.deadline_date}
                onChange={handleChange("deadline_date")}
                min={
                  state.deadline_date
                    ? ""
                    : moment(new Date()).format("yyyy-MM-D")
                }
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("publicationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="publication_date"
                value={state.publication_date}
                onChange={handleChange("publication_date")}
                min={
                  state.publication_date
                    ? ""
                    : moment(new Date()).format("yyyy-MM-D")
                }
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("winnerannouncement")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="winner_announcement_date"
                value={state.winner_announcement_date}
                onChange={handleChange("winner_announcement_date")}
                min={
                  state.winner_announcement_date
                    ? ""
                    : moment(new Date()).format("yyyy-MM-D")
                }
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("judgingstartdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="judgingstartdate"
                value={state.judging_start_date}
                onChange={handleChange("judging_start_date")}
                min={
                  state.judging_start_date
                    ? ""
                    : moment(new Date()).format("yyyy-MM-D")
                }
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("judgingenddate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="judgingenddate"
                value={state.judging_end_date}
                onChange={handleChange("judging_end_date")}
                min={moment(state.judging_start_date).format("yyyy-MM-D")}
                required
              ></input>
            </div>
          </div>

          <Multiselect
            title={"category"}
            options={categorylist}
            labelField={"name"}
            valueField={"slug"}
            values={state.category}
            onChange={handleSelect("category")}
            state={state.category}
          />

          <div className="flexrows">
            <div className="genretags">
              {fileName !== "" ? (
                <div className="upload-info-file-container">
                  <p>Resources name: {fileName}</p>
                  <span onClick={() => onDeleteFile()}>delete file</span>
                </div>
              ) : (
                <div className="upload-file">
                  <input
                    type="file"
                    name="file"
                    accept=".zip,.rar,.7z,.gz"
                    onChange={handleFile}
                    ref={inputRef}
                    className="input-resource"
                  />

                  <div className="upload-file-ref">
                    <p className="">resources</p>
                    <span
                      className="upload-zip-contests"
                      onClick={handleClickResource}
                    >
                      Upload file
                    </span>
                  </div>
                </div>
              )}

              <Multiselect
                title={"level"}
                options={levellist}
                labelField={"name"}
                valueField={"slug"}
                value={state.level}
                onChange={handleSelect("level")}
                state={state.level}
              />
            </div>
          </div>

          <div className="flexrows">
            <div className="checkbox-container">
              <div className="checkbox-content checkbox-container">
                <p>Show in platform ?</p>

                <label className="container">
                  YES
                  <input
                    type="radio"
                    name="in_platform"
                    value={true}
                    defaultChecked={state.in_platform ? true : false}
                    onChange={handleChange("in_platform")}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container">
                  NO
                  <input
                    type="radio"
                    name="in_platform"
                    value={false}
                    defaultChecked={!state.in_platform ? true : false}
                    onChange={handleChange("in_platform")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              <div className="checkbox-content checkbox-container">
                <p>Can host announce winner ?</p>

                <label className="container">
                  YES
                  <input
                    type="radio"
                    name="host_can_announce_winner"
                    value={true}
                    defaultChecked={
                      state.host_can_announce_winner ? true : false
                    }
                    onChange={handleChange("host_can_announce_winner")}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container">
                  NO
                  <input
                    type="radio"
                    value={false}
                    name="host_can_announce_winner"
                    defaultChecked={
                      !state.host_can_announce_winner ? true : false
                    }
                    onChange={handleChange("host_can_announce_winner")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <DynamicInputs
            placeholder={"Rules input..."}
            val={state.rules}
            type="rules"
            title="addinputsrules"
            setState={handleSelect("rules")}
            inputName="Rules"
          />

          <DynamicInputs
            placeholder={"Reward input..."}
            val={state.reward}
            type="reward"
            inputName="Rewards"
            title="addinputsrewards"
            setState={handleSelect("reward")}
          />

          <button className="uploadbutton" type="submit">
            {editdata ? translate("update") : translate("upload")}
          </button>
        </form>
      </div>
    </LoadingOverlay>
  );
};

export default UploadContests;
