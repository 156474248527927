import React from "react";

function TxtArea({ title, placeholder, name, value, onChange, rows, cols,textareaClassName }) {
  return (
    <div className="textareaedituser">
      <label>{title}</label>
      <span className="divider"></span>
      <textarea
      className={textareaClassName}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        rows={rows}
        cols={cols}
        required
      ></textarea>
    </div>
  );
}

export default TxtArea;
