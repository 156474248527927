import React, { useEffect, useState } from "react";

export const useClientSidePagination = (section) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(4);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  useEffect(() => {
    setPageIndex(1);
    setmaxPageNumberLimit(4);
    setminPageNumberLimit(0);
  }, [section]);

  return {
    pageIndex,
    setPageIndex,
    maxPageNumberLimit,
    setmaxPageNumberLimit,
    minPageNumberLimit,
    setminPageNumberLimit,
  };
};
