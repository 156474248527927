import React, { useEffect, useState, useRef } from "react";
import GenericSelect from "../../../../components/design-system/select";
import InputText from "../../../../components/design-system/inputText";
import TxtArea from "../../../../components/design-system/txtArea";
import translate from "../../../../utils/locale/language";
import {
  getMethod,
  getMethodOpen,
  patchMethodAuthenticatedWithBody,
  postMethodAuthenticated,
} from "../../../../utils/apis/services/api";
import urls from "../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";

const PartnerForm = ({ editData, goBack }) => {
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    id: editData?.id || null,
    partnername: editData?.user?.full_name || "",
    iban: editData?.iban || "",
    affiliationlink: editData?.affiliation || "",
    billaddress: editData?.billing_address || "",
    email: editData?.user?.email || "",
    bio: editData?.user?.bio || "",
    city: editData?.user?.location || "",
    image: editData?.user?.profile_image || "",
    genre: editData?.user?.genre || "",
    role: editData?.user?.role || "",
  });
  const [profileimage, setProfileimg] = useState(null);
  const [profile_image, setProfile_image] = useState(null);
  const [genrelist, setGenreList] = useState([]);
  const [rolelist, setRoleList] = useState([]);
  const imageRef = useRef();

  const setValue = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImage = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setProfile_image(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setProfileimg(result.data.id);
  };

  const getRoleList = async () => {
    const rolelist = await getMethod(urls.getcateogry);
    setRoleList(rolelist && rolelist.data ? rolelist.data : []);
  };

  const getGenreList = async () => {
    let genrelist = await getMethodOpen(urls.genrelist);
    setGenreList(genrelist && genrelist.data ? genrelist.data : []);
  };

  const setRoleCB = (value) => setValue("role", value);
  const setGenreCB = (value) => setValue("genre", value);

  const handleClickImage = () => {
    imageRef.current.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    setLoader(true);

    let body = {
      user: {
        role: state?.role?.slug,
        genre: state?.genre?.slug,
        full_name: state?.partnername,
        location: state?.city,
        bio: state?.bio,
      },
      iban: state.iban,
      billing_address: state?.billaddress,
      affiliation: state?.affiliationlink,
    };

    if (profileimage !== null) {
      let imageupadte = {
        profile_image: profileimage,
      };
      Object.assign(body.user, imageupadte);
    }
    if (state?.email !== editData?.user?.email) {
      const email = {
        email: state?.email,
      };
      Object.assign(body.user, email);
    }

    let result = await patchMethodAuthenticatedWithBody(
      urls.getallpartner + state.id + "/",
      body
    );

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success("Partner updated successfully!");
      setLoader(false);
      goBack(2);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoader(true);
    let body = {
      user: {
        email: state?.email,
        role: state?.role?.slug,
        genre: state?.genre?.slug,
        full_name: state?.partnername,
        location: state?.city,
        bio: state?.bio,
        profile_image: profileimage,
      },
      iban: state.iban,
      billing_address: state?.billaddress,
      affiliation: state?.affiliationlink,
    };
    let result = await postMethodAuthenticated(urls.getallpartner, body);

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(translate("invitationsent"));
      setLoader(false);
      goBack(2);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
    }
  };

  useEffect(() => {
    getGenreList();
    getRoleList();
  }, []);

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="pageheading">
        <h2>{translate("partner")}</h2>{" "}
        <p
          onClick={() => {
            goBack(2);
          }}
        >
          {"< partner list"}
        </p>
      </div>
      <div>
        <h3 className="partnerheading">
          <b> {editData ? "Edit partner" : translate("createnewpartner")}</b>
        </h3>

        <div className="createpartner">
          <form onSubmit={editData ? handleEdit : handleSubmit}>
            <div className="createpartnerform">
              <div className="imageselector" onClick={handleClickImage}>
                <label className="custom-file-upload">
                  <input
                    ref={imageRef}
                    type="file"
                    onChange={handleImage}
                    accept="image"
                  ></input>
                </label>
                <img
                  className={
                    profile_image
                      ? "uploadedimage"
                      : editData
                      ? "uploadedimage"
                      : "uploadsymbol"
                  }
                  src={
                    profile_image
                      ? URL.createObjectURL(profile_image)
                      : editData
                      ? state.image
                      : "uploadicon"
                  }
                  alt=""
                />
              </div>

              <div className="edituserfields" style={{ width: "100%" }}>
                <InputText
                  title={"name"}
                  placeholder="Enter partner name"
                  value={state.partnername}
                  name="partnername"
                  onChange={handleChange}
                  type="text"
                />

                <InputText
                  title={"location"}
                  placeholder="Enter partner city"
                  value={state.city}
                  name="city"
                  onChange={handleChange}
                  type="text"
                />

                <InputText
                  title={"affiliation"}
                  placeholder="Add affliate link"
                  name="affiliationlink"
                  value={state.affiliationlink}
                  onChange={handleChange}
                  type="text"
                />

                <div className="flexrows">
                  <InputText
                    title={"iban"}
                    placeholder="For royalty payments"
                    name="iban"
                    onChange={handleChange}
                    value={state.iban}
                    type="text"
                  />

                  <InputText
                    title={"billing"}
                    placeholder="Add billing address..."
                    name="billaddress"
                    onChange={handleChange}
                    value={state.billaddress}
                    type="text"
                  />
                </div>

                <InputText
                  title={"email"}
                  placeholder="Partner E-mail"
                  name="email"
                  onChange={handleChange}
                  value={state.email}
                  type="text"
                />

                <div className="flexrows">
                  <GenericSelect
                    title={"role"}
                    options={rolelist}
                    labelField={"name"}
                    valueField={"slug"}
                    value={state?.role}
                    onSet={setRoleCB}
                  />

                  <GenericSelect
                    title={"maingenre"}
                    options={genrelist}
                    labelField={"genre_name"}
                    valueField={"slug"}
                    value={state?.genre}
                    onSet={setGenreCB}
                  />
                </div>

                <div className="textareaedituser">
                  <TxtArea
                    title={"bio"}
                    placeholder="Enter something about yourself to describe you better..."
                    name="bio"
                    onChange={handleChange}
                    value={state.bio}
                  />
                </div>
              </div>
            </div>

            <button className="sendinvitation">
              {editData ? "edit invitation" : translate("sendinvitation")}
            </button>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default PartnerForm;
