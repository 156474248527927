import React from "react";

function UploadImg({ editdata, cover_image, handleCoverImage }) {
  return (
    <div className="coverimageselector">
      <label className="custom-file-upload">
        <input type="file" onChange={handleCoverImage} accept="image"></input>
        <img
          className={
            cover_image
              ? "uploadedimage"
              : editdata
              ? "uploadedimage"
              : "uploadsymbol"
          }
          src={
            cover_image
              ? URL.createObjectURL(cover_image)
              : editdata
              ? editdata
              : "uploadicon"
          }
          alt=""
        />
        {cover_image || editdata ? "" : <p>Select Course Image</p>}
      </label>
    </div>
  );
}

export default UploadImg;
