import React, { useEffect, useState } from "react";
import "./uploadchart.scss";
import translate from "../../../../../utils/locale/language";
import {
  getMethod,
  getMethodOpen,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import GenericSelect from "../../../../../components/design-system/select";
import UploadImg from "../../../../../components/design-system/uploadImg";
import InputText from "../../../../../components/design-system/inputText";
import TxtArea from "../../../../../components/design-system/txtArea";

function Uploadchart({ editdata, setSection, setedit }) {
  const [partnerlist, setPartnerList] = useState([]);
  const [coverimage, setCoverimg] = useState(null);
  const [cover_image, setCover_image] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedtracklinks, setTracklinks] = useState(
    editdata ? editdata.tracks : []
  );

  const [state, setState] = useState({
    chart_image: editdata ? editdata.cover_image : null,
    chartname: editdata ? editdata.chart_name : "",
    description: editdata ? editdata.description : "",
    genre: editdata ? editdata.genre : null,
    publication_date: editdata ? editdata.publication_date : null,
    expiration_date: editdata ? editdata.expiration_date : null,
    author: editdata ? editdata.author : null,
    tracks: editdata ? editdata.tracks : [],
    track_count: editdata ? editdata.track_count : 0,
    audio_preview: editdata ? editdata.audio_preview : null,
  });

  const [genrelist, setGenreList] = useState([]);
  const [author, setAuthor] = useState("");
  const [genre, setGenre] = useState("");
  const [is_trending, setisTrending] = useState(
    editdata ? editdata.is_trending : false
  );

  const handleFile = async (e, index) => {
    e.preventDefault();
    let formData = new FormData();
    const file = e.target.files[0];
    formData.append("file", file);
    let result = await postMethodAuthenticated(urls.fileupload, formData);

    const { name } = e.target;

    const newTracks = selectedtracklinks.map((obj, i) => ({
      ...obj,
      [name]: i === index ? result.data.id : obj.audio_preview,
    }));

    setTracklinks(newTracks);
  };

  const onDeleteFile = (index) => {
    const newTracks = selectedtracklinks.map((obj, i) => ({
      ...obj,
      audio_preview: i === index ? "" : obj.audio_preview,
    }));

    setTracklinks(newTracks);
  };

  const getGenreList = async () => {
    let genrelist = await getMethodOpen(urls.genrelist);
    setGenreList(genrelist && genrelist.data ? genrelist.data : []);
  };

  const getPartnersList = async () => {
    let partnerslist = await getMethod(urls.getroles + "dj-partner");
    setPartnerList(partnerslist && partnerslist.data ? partnerslist.data : []);
  };

  const handleCoverImage = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setCover_image(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setCoverimg(result.data.id);
    setLoader(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange2 = (type) => (e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handletrackcount = () => {
    let body = {
      track_url: "",
      bpm: "",
      genre: "",
      artist_name: "",
      tracktitle: "",
      trackinfo: null,
      audio_preview: "",
    };

    setTracklinks([...selectedtracklinks, body]);
  };

  const handleTrackChange = (e, trackindex) => {
    const { name, value } = e.target;

    selectedtracklinks.map((item, index) => {
      if (trackindex === index) {
        selectedtracklinks[index] = {
          ...selectedtracklinks[index],
          [name]: value,
        };
      }
    });
  };

  const handleTrackChange2 = (trackindex) => (e) => {
    const { name, value } = e.target;

    selectedtracklinks.map((item, index) => {
      if (trackindex === index) {
        selectedtracklinks[index] = {
          ...selectedtracklinks[index],
          [name]: value,
        };
      }
    });
  };

  const deletetrack = (index) => {
    selectedtracklinks.splice(index, 1);
    setTracklinks([...selectedtracklinks]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (genre === "" || author === "") {
      toast.error(translate("fillrequiredfields"));
    } else {
      setLoader(true);
      let body = {
        content_type: "chart",
        cover_image: coverimage,
        chart_name: state.chartname,
        description: state.description,
        genre: genre.slug,
        publication_date: state.publication_date,
        expiration_date: state.expiration_date,
        author: author.id,
        tracks: selectedtracklinks.map((item) => item),
        is_trending: is_trending,
      };

      let result = await postMethodAuthenticated(urls.uploadchart, body);

      if (result.data && !result.data.ErrorCode && result.status === 200) {
        toast.success(result.data.message);
        setSection(1);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(result.data.Error);
        setLoader(false);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    setLoader(true);
    let body = {
      chart_name: state.chartname,
      description: state.description,
      genre: genre !== "" ? genre.slug : state.genre.slug,
      publication_date: state.publication_date,
      expiration_date: state.expiration_date,
      tracks: selectedtracklinks.map((item) => item),
      is_trending: is_trending,
    };

    if (coverimage) {
      body = { ...body, cover_image: coverimage };
    }

    let result = await putMethodAuthenticated(
      urls.uploadchart + editdata.id + "/",
      body
    );
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setedit();
      setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getGenreList();
    getPartnersList();
  }, []);

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="uploadcharts">
        <h2 className="heading">{"Charts"}</h2>
        <form onSubmit={editdata ? handleUpdate : handleSubmit}>
          <UploadImg
            editdata={state.chart_image}
            cover_image={cover_image}
            handleCoverImage={handleCoverImage}
          />

          <div className="flexrows">
            <GenericSelect
              title={"author"}
              options={partnerlist}
              labelField={editdata ? "name" : "full_name"}
              valueField={"id"}
              value={author}
              state={state.author}
              onSet={setAuthor}
              disabled={editdata ? true : false}
            />

            <GenericSelect
              title={"genre"}
              options={genrelist}
              labelField={state.genre ? "name" : "genre_name"}
              valueField={"slug"}
              value={genre}
              state={state.genre}
              onSet={setGenre}
            />

            <div
              className="custom-control custom-checkbox"
              style={{ width: "50%" }}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
                defaultChecked={state.is_trending}
                onChange={(e) => setisTrending(e.currentTarget.checked)}
              />
              <label
                className="custom-control-label rememberme"
                htmlFor="customCheck1"
              >
                {"Is Trending"}
              </label>
            </div>
          </div>

          <InputText
            title={"name"}
            placeholder={"chartname name..."}
            value={state.chartname}
            onChange={handleChange2("chartname")}
            name="chartname"
            type="text"
          />

          <TxtArea
            title={"Description"}
            placeholder={"Charts description..."}
            name={"description"}
            onChange={handleChange2("description")}
            value={state.description}
          />

          <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("publicationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="publication_date"
                value={state.publication_date}
                onChange={handleChange}
                min={
                  state.publication_date
                    ? ""
                    : moment(new Date()).format("yyyy-MM-D")
                }
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("expirationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="expiration_date"
                value={state.expiration_date}
                onChange={handleChange}
                min={
                  state.expiration_date
                    ? ""
                    : moment(new Date()).format("yyyy-MM-D")
                }
                required
              ></input>
            </div>
          </div>

          {selectedtracklinks.map((item, index) => {
            return (
              <div key={index} className="sampletrackscontainer">
                <div className="sampletrackstitle">
                  <p className="dashline">{"---" + (index + 1)}</p>
                </div>
                <div className="flexrows">
                  <div className="textfieldedituser">
                    <label>{"Track link"}</label>
                    <span className="divider"></span>
                    <input
                      type="text"
                      name="track_url"
                      placeholder="Paste Beatport track link here..."
                      onChange={(e) => handleTrackChange(e, index)}
                      defaultValue={item?.track_url || " "}
                      required
                    ></input>
                  </div>
                  <i
                    className="fas fa-trash-alt"
                    style={{ float: "right" }}
                    onClick={() => deletetrack(index)}
                  ></i>
                </div>

                <div className="flexrows">
                  <div className="textfieldedituser">
                    <label>{"Title"}</label>
                    <span className="divider"></span>
                    <input
                      title={"title"}
                      placeholder={"Track title..."}
                      onChange={handleTrackChange2(index)}
                      name="tracktitle"
                      type="text"
                      defaultValue={item?.tracktitle || " "}
                      required
                    ></input>
                  </div>

                  <div className="textfieldedituser">
                    <label>{"Artist"}</label>
                    <span className="divider"></span>
                    <input
                      title={"artist"}
                      placeholder="Enter artist name here..."
                      onChange={handleTrackChange2(index)}
                      name="artist_name"
                      type="text"
                      defaultValue={item?.artist_name || " "}
                      required
                    ></input>
                  </div>
                </div>

                <div className="flexrows">
                  <div className="textfieldedituser">
                    <label>{"bpm"}</label>
                    <span className="divider"></span>
                    <input
                      title={"bpm"}
                      placeholder="BPM..."
                      onChange={handleTrackChange2(index)}
                      name="bpm"
                      type="text"
                      defaultValue={item?.bpm || " "}
                      required
                    ></input>
                  </div>

                  <div className="textfieldedituser">
                    <label>{"genre"}</label>
                    <span className="divider"></span>
                    <input
                      title={"genre"}
                      placeholder="Enter genre"
                      onChange={handleTrackChange2(index)}
                      name="genre"
                      type="text"
                      defaultValue={item?.genre || " "}
                      required
                    ></input>
                  </div>
                </div>

                <div className="flexrows">
                  {item?.audio_preview ? (
                    <div className="upload-info-file-container">
                      <p>Resources name: {item.audio_preview}</p>
                      <span onClick={() => onDeleteFile(index)}>
                        delete file
                      </span>
                    </div>
                  ) : (
                    <div className="upload-file">
                      <input
                        type="file"
                        name="audio_preview"
                        accept="audio/*"
                        onChange={(e) => handleFile(e, index)}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <label
            className="addtrackbutton"
            onClick={() => handletrackcount()}
            style={{ width: "10%" }}
          >
            {"Add track"}
          </label>

          <button className="uploadbutton" type="submit">
            {editdata ? translate("update") : translate("upload")}
          </button>
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default Uploadchart;
