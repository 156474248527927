import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./sidenav.scss";
import partner from "../../assets/images/dashboard/partner.svg";

import uploaddocument from "../../assets/images/dashboard/uploaddocument.svg";
import userlist from "../../assets/images/dashboard/userlist.svg";
import course from "../../assets/images/uploadcontent/course.svg";
import samples from "../../assets/images/uploadcontent/samples.svg";
import charts from "../../assets/images/uploadcontent/charts.svg";
import SubMenuNavLinks from "./submenunavlinks";

const navLinks = [
  {
    image: partner,
    url: "/partner/tutors",
    name: "Partner-Tutor",
    id: 0,
    submenus: [
      { image: partner, url: "/partner/tutors", name: "Tutors" },
      { image: partner, url: "/partner/labelagency", name: "Label & Agency" },
    ],
  },
  // { image: royalties, url: "/royalties", name: "Royalties", id: 1 },
  {
    image: uploaddocument,
    url: "/uploadcontent/course",
    name: "Upload Content",
    submenus: [
      { image: course, url: "/uploadcontent/course", name: "Upload Courses" },
      { image: course, url: "/uploadcontent/boundle", name: "Upload Boundles" },
      {
        image: samples,
        url: "/uploadcontent/samplelibrary",
        name: "Sample Library",
      },
      { image: charts, url: "/uploadcontent/charts", name: "Charts" },
    ],
    id: 2,
  },
  {
    image: uploaddocument,
    url: "/uploadtags/djing",
    name: "Upload Tags",
    submenus: [
      { image: course, url: "/uploadtags/djing", name: "Djing Tags" },
      { image: course, url: "/uploadtags/producing", name: "Producing Tags" },
      {
        image: course,
        url: "/uploadtags/music",
        name: "Music Tags",
      },
    ],
    id: 3,
  },
  {
    image: uploaddocument,
    url: "/pathmanagment/singlepath",
    name: "Path Management",
    submenus: [
      { image: course, url: "/pathmanagment/singlepath", name: "Single Path" },
    ],
    id: 4,
  },
  {
    image: uploaddocument,
    url: "/heroesmanagment/heroes",
    name: "Heroes Management",
    submenus: [
      { image: course, url: "/heroesmanagment/heroes", name: "Heroes" },
    ],
    id: 5,
  },
  {
    image: uploaddocument,
    url: "/contestsmanagment/contests",
    name: "Contests Management",
    submenus: [
      { image: course, url: "/contestsmanagment/contests", name: "Contests" },
    ],
    id: 6,
  },
  { image: userlist, url: "/resetpassword", name: "Reset Password", id: 7 },
];

function SideNav(props) {
  const [submenu, setSubmenu] = useState("");

  return (
    <div>
      <div className={props.menu ? "menu" : "menu active"}>
        {
          <ul>
            {navLinks.map(({ id, url, name, image, submenus }) => (
              <li key={id}>
                <NavLink
                  exact
                  to={() => url}
                  className="nav-link"
                  onClick={(e) =>
                    url === "/uploadcontent/course"
                      ? setSubmenu("content")
                      : url === "/uploadtags/djing"
                      ? setSubmenu("tags")
                      : url === "/pathmanagment/singlepath"
                      ? setSubmenu("path")
                      : url === "/heroesmanagment/heroes"
                      ? setSubmenu("heroes")
                      : url === "/contestsmanagment/contests"
                      ? setSubmenu("contests")
                      : url === "/partner/tutors"
                      ? setSubmenu("partner")
                      : null
                  }
                  activeClassName="link-active"
                  style={{ color: "#858585" }}
                >
                  <img src={image} alt={name} />
                  {name}
                </NavLink>
                {
                  <ul>
                    {((submenu === "content" &&
                      url === "/uploadcontent/course") ||
                      (submenu === "tags" && url === "/uploadtags/djing") ||
                      (submenu === "path" &&
                        url === "/pathmanagment/singlepath") ||
                      (submenu === "heroes" &&
                        url === "/heroesmanagment/heroes") ||
                      (submenu === "contests" &&
                        url === "/contestsmanagment/contests") ||  (submenu === "partner" &&
                        url === "/partner/tutors")) &&
                      submenus.map(({ url, name, image }) => (
                        <SubMenuNavLinks
                          key={name}
                          url={url}
                          name={name}
                          image={image}
                        />
                      ))}
                  </ul>
                }
              </li>
            ))}
          </ul>
        }
      </div>
    </div>
  );
}

export default SideNav;
