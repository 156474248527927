import React, { useEffect, useState, useRef } from "react";
import "./uploadcourses.scss";
import translate from "../../../../../utils/locale/language";
import Select from "react-dropdown-select";
import userpalceholder from "../../../../../assets/images/dashboard/userplaceholder.jpg";
import {
  getMethod,
  getMethodOpen,
  postMethodAuthenticated,
  putMethodAuthenticated,
  deleteMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import GenericSelect from "../../../../../components/design-system/select";
import Multiselect from "../../../../../components/design-system/multiselect";
import Checkbox from "../../../../../components/design-system/checkbox";
import TxtArea from "../../../../../components/design-system/txtArea";
import UploadImg from "../../../../../components/design-system/uploadImg";
import InputText from "../../../../../components/design-system/inputText";

function UploadCourses(props) {
  const [showlabel, setShowLabel] = useState(false);
  const [searchterm, setsearch] = useState("");
  const [selectedlabels, setSelectedlabels] = useState(
    props.editdata ? props.editdata.correlated_partners : []
  );
  const [tutorlist, setTutorsList] = useState([]);
  const [courseStatusList, setCourseStatusList] = useState([]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [coverimage, setCoverimg] = useState(null);
  const [cover_image, setCover_image] = useState(null);
  const [loader, setLoader] = useState(false);
  const [courseclasses, setCourseClasses] = useState(false);
  const [state, setState] = useState({
    coursename: props.editdata ? props.editdata.name : "",
    tutor: props.editdata ? props.editdata.tutor : "",
    description: props.editdata ? props.editdata.description : "",
    language: props.editdata ? props.editdata.language : "",
    subtitle: props.editdata ? props.editdata.subtitle : "",
    duration: props.editdata ? props.editdata.duration : "",
    publicationdate: props.editdata ? props.editdata.publication_date : null,
    expirationdate: props.editdata ? props.editdata.expiration_date : null,
    genre: props.editdata ? props.editdata.genre : null,
    category: props.editdata ? props.editdata.category : null,
    courseStatus: props.editdata ? props.editdata.status : null,
    price: props.editdata ? props.editdata.price : null,
    discount_price: props.editdata ? props.editdata.discount_price : null,
    learn1: props.editdata ? props.editdata.learn1 : "",
    learn2: props.editdata ? props.editdata.learn2 : "",
    learn3: props.editdata ? props.editdata.learn3 : "",
    learn4: props.editdata ? props.editdata.learn4 : "",
    trailer: props.editdata ? props.editdata.trailer : "",
    level: props.editdata ? props.editdata.level : "",
    tags: props.editdata ? props.editdata.tags : null,
    inShop: props.editdata ? props.editdata.in_shop : null,
    isTrending: props.editdata ? props.editdata.is_trending : null,
    status: props.editdata ? props.editdata.status : null,
  });

  const [isTrending, setIsTrending] = useState(
    props.editdata ? state.isTrending : false
  );
  const [inShop, setInShop] = useState(props.editdata ? state.inShop : false);
  const [genrelist, setGenreList] = useState([]);
  const [levellist, setLevelList] = useState([]);
  const [level, setLevel] = useState(null);
  const [category, setCategory] = useState([]);
  const [categorylist, setCategoryList] = useState([]);
  const [genre, setGenre] = useState(null);
  const [tagList, setTaglist] = useState([]);
  const [tags, setTags] = useState([]);
  const [courseStatus, setCourseStatus] = useState("");
  const [classstate, setClassState] = useState({
    classname: "",
    vimeolink: "",
    description: "",
    classimage: null,
    publicationdate: null,
    duration: "",
    order_number: "",
    errors: {
      classname: "",
      vimeolink: "",
      publicationdate: null,
      description: "",
      duration: "",
    },
  });
  const [selectedclasses, setSelectedClasses] = useState(
    props.editdata ? props.editdata.classes : []
  );
  const [editclass, setEditClass] = useState(null);

  let labelSearchResult =
    tutorlist &&
    tutorlist.filter((label) => {
      return (
        label.full_name && label.full_name.toLowerCase().includes(searchterm)
      );
    });

  const addLabel = (item, index) => {
    selectedlabels.push(item);
    tutorlist.splice(index, 1);
    setShowLabel(!showlabel);
  };

  const labelstags = (item) => {
    return (
      <>
        <img
          alt=""
          className="customlabelimage"
          src={item.profile_image ? item.profile_image : userpalceholder}
        ></img>
      </>
    );
  };

  const getGenreList = async () => {
    let genrelist = await getMethodOpen(urls.genrelist);
    setGenreList(genrelist && genrelist.data ? genrelist.data : []);
  };

  const getLevelList = async () => {
    let levellist = await getMethod(urls.getlevel);
    setLevelList(levellist && levellist.data ? levellist.data : []);
  };

  const getCatogeoryList = async () => {
    let catogeorylist = await getMethodOpen(urls.getcoursecategory);
    setCategoryList(
      catogeorylist && catogeorylist.data ? catogeorylist.data : []
    );
  };

  const getCourseStatusList = async () => {
    let courseList = await getMethodOpen(urls.coursestatuslist);
    setCourseStatusList(courseList.data ? courseList.data : []);
  };

  const getTagList = async () => {
    const normalizeCategory =
      category.length > 0
        ? category.map((el) => el.slug).join(",")
        : props.editdata && props.editdata
        ? props.editdata.category.map((el) => el.slug).join(",")
        : "";
    let tagList = await getMethodOpen(
      `${urls.tagList}?filtertype=${normalizeCategory}`
    );
    setTaglist(tagList.data ? tagList.data : []);
  };

  const getTutorsList = async () => {
    let tutorslist = await getMethod(urls.getroles + "tutors");
    setTutorsList(tutorslist && tutorslist.data ? tutorslist.data : []);
  };

  const languages = [
    {
      name: "Spanish",
      slug: "sp",
    },
    {
      name: "English",
      slug: "en",
    },
    {
      name: "Italian",
      slug: "it",
    },
    {
      name: "Dutch",
      slug: "nl",
    },
    {
      name: "French",
      slug: "fr",
    },
  ];

  const handleRemoveImage = (type) => {
    if (type === "resource") {
      const newArray = selectedclasses.map((item, index) => {
        if (editclass === index) {
          return (selectedclasses[index] = {
            ...selectedclasses[index],
            resource: null,
          });
        } else return item;
      });
      setSelectedClasses(newArray);
    } else {
      setFileName(null);
      setFile(null);
    }
  };

  const handleImage = index => async (e) => {
    e.preventDefault();
    setLoader(true);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setFileName({name:e.target.files[0].name,index});
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setFile(result.data.id);
    setLoader(false);
  };

  const handleCoverImage = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setCover_image(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setCoverimg(result.data.id);
    setLoader(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClassChange = (e) => {
    const { name, value } = e.target;
    setClassState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    let errors = classstate.errors;

    switch (name) {
      case "vimeolink":
        errors.vimeolink = value.length < 10 ? "Link is not valid!" : "";
        break;

      case "classname":
        errors.classname = value.length < 1 ? "Classname is not valid!" : "";
        break;

      case "publicationdate":
        errors.publicationdate = value !== null ? "Date is not valid!" : "";
        break;

      case "description":
        errors.description =
          value.length < 1 ? "Please enter description." : "";
        break;

      case "duration":
        errors.duration = value.length < 3 ? "Please enter duration." : "";
        break;
      default:
        break;
    }
  };

  const handleClassEdit = (e) => {
    const { name, value } = e.target;
    selectedclasses.map((item, index) => {
      if (editclass === index) {
        selectedclasses[index] = {
          ...selectedclasses[index],
          [name]: value,
        };
      }
    });
  };

  const deleteclass = async (id) => {
    setLoader(true);
    let result = await deleteMethodAuthenticated(urls.uploadclasses + id + "/");
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      props.setSection();
      setLoader(false);
      setEditClass(null);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (genre === "" || category.length < 0) {
      toast.error(translate("fillrequiredfields"));
    } else {
      setLoader(true);
      let body = {
        course_image: coverimage,
        name: state.coursename,
        affiliate_link: "seedj.app/" + state.coursename,
        tutor: state.tutor,
        description: state.description,
        genre: genre,
        language: state.language,
        category: category.map((item) => item.slug),
        subtitle: state.subtitle,
        duration: state.duration,
        publication_date: state.publicationdate,
        expiration_date: state.expirationdate,
        correlated_partners: selectedlabels.map((item) => item.id),
        classes: selectedclasses,
        tags: tags,
        status: courseStatus,
        learn1: state.learn1,
        learn2: state.learn2,
        learn3: state.learn3,
        learn4: state.learn4,
        in_shop: inShop,
        is_trending: isTrending,
        discount_price: state.discount_price,
        price: state.price,
        trailer: state.trailer,
        level: level ? level.slug : state.level.slug,
      };

      let result = await postMethodAuthenticated(urls.uploadcontent, body);
      if (result.data && !result.data.ErrorCode && result.status === 200) {
        toast.success(result.data.message);
        props.setSection();
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(result.data.Error);
        setLoader(false);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    if (
      (genre === "" && props.editdata.genre === "") ||
      (category.length < 0 && props.editdata.category.length < 0)
    ) {
      toast.error(translate("fillrequiredfields"));
    } else {
      setLoader(true);
      let body = {
        id: props.editdata.id,
        content_type: "course",
        name: state.coursename,
        affiliate_link: "seedj.app/" + state.coursename,
        tutor: state.tutor,
        description: state.description,
        genre: genre || state.genre,
        language: state.language,
        category:
          category.length > 0
            ? category.map((item) => item.slug)
            : state.category.map((item) => item.slug),
        subtitle: state.subtitle,
        duration: state.duration,
        publication_date: state.publicationdate,
        expiration_date: state.expirationdate,
        correlated_partners: selectedlabels.map((item) => item.id),
        classes: selectedclasses,
        tags: tags.length > 0 ? tags : state.tags,
        status: courseStatus !== "" ? courseStatus : state.courseStatus,
        learn1: state.learn1,
        learn2: state.learn2,
        learn3: state.learn3,
        learn4: state.learn4,
        in_shop: inShop !== state.inShop ? inShop : state.inShop,
        is_trending:
          isTrending !== state.isTrending ? isTrending : state.isTrending,
        discount_price: state.discount_price,
        price: state.price,
        trailer: state.trailer,
        level: level ? level.slug : state.level.slug,
      };

      if (coverimage !== null) {
        Object.assign(body, { course_image: coverimage });
      }

      let result = await putMethodAuthenticated(
        urls.uploadcontent + props.editdata.id + "/",
        body
      );

      if (result.data && !result.data.ErrorCode && result.status === 200) {
        toast.success(result.data.message);
        props.setedit();
        props.setSection();
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(result.data.Error);
        setLoader(false);
      }
    }
  };

  const onUploadClass = (e) => {
    e.preventDefault();
    const newArray = selectedclasses.map((item, index) => {
      if (editclass === index) {
        return (selectedclasses[index] = {
          ...selectedclasses[index],
          resource: file ? file : item.resource,
        });
      } else return item;
    });
    setSelectedClasses(newArray);
    setEditClass(null);
  };

  const handleCategory = (values) => {
    setCategory(values);
  };

  const addMoreClass = (e, val) => {
    e.preventDefault();
    let body = {
      name: classstate.classname,
      vimeolink: classstate.vimeolink,
      description: classstate.description,
      classimage: classstate.classimage,
      publication_date: classstate.publicationdate,
      resource: file,
      duration: classstate.duration,
      order_number: classstate.order_number,
    };
    setSelectedClasses([...selectedclasses, body]);
    setCourseClasses(val === "next" ? true : false);
    setFileName(null);
    setFile(null);
    // }
  };

  useEffect(() => {
    getGenreList();
    getCatogeoryList();
    getLevelList();
    // getPartnersList()
    getTutorsList();
    getCourseStatusList();
  }, []);

  useEffect(() => {
    getTagList();
  }, [category, props.editdata]);

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="uploadcourses">
        <h2 className="heading">{translate("course")}</h2>
        <form onSubmit={props.editdata ? handleUpdate : handleSubmit}>
          <UploadImg
            editdata={props.editdata?.course_image}
            cover_image={cover_image}
            handleCoverImage={handleCoverImage}
          />

          <div className="textfieldedituser">
            <label>{translate("name")}</label>
            <span className="divider"></span>
            <input
              placeholder="Write the name of course here..."
              value={state.coursename}
              name="coursename"
              onChange={handleChange}
              required
            ></input>
          </div>

          <div className="textfieldedituser">
            <label>{translate("tutor")}</label>
            <span className="divider"></span>
            <div className="dropdownselector">
              <Select
                options={tutorlist}
                labelField={"full_name"}
                valueField={"id"}
                values={props.editdata ? [props.editdata.tutor] : [state.tutor]}
                onChange={(values) =>
                  setState((prevState) => ({
                    ...prevState,
                    tutor: values[0].id,
                  }))
                }
                className="ReactDropdownSelect"
              />
            </div>
          </div>

          <TxtArea
            title={translate("description")}
            placeholder="Describe your video to let people know what it is…"
            value={state.description}
            name="description"
            onChange={handleChange}
          />

          <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("language")}</label>
              <span className="divider"></span>
              <div className="dropdownselector">
                <Select
                  options={languages}
                  values={
                    props.editdata
                      ? [props.editdata.language]
                      : [state.language]
                  }
                  labelField={"name"}
                  valueField={"slug"}
                  onChange={(values) =>
                    setState((prevState) => ({
                      ...prevState,
                      language: values[0],
                    }))
                  }
                  className="ReactDropdownSelect"
                />
              </div>
            </div>

            <InputText
              title={"subtitle"}
              placeholder={"Subtitle..."}
              value={state.subtitle}
              onChange={handleChange}
              name="subtitle"
              type="text"
            />
          </div>

          <InputText
            title={"duration"}
            placeholder={"Duration..."}
            name="duration"
            value={state.duration}
            onChange={handleChange}
            type="text"
          />

          <div className="flexrows">
            <GenericSelect
              title={"genre"}
              options={genrelist}
              labelField={"genre_name"}
              valueField={"id"}
              value={genre}
              state={state.genre}
              onSet={setGenre}
            />

            <GenericSelect
              title={"courseStatus"}
              options={courseStatusList}
              labelField={"status_name"}
              valueField={"slug"}
              value={courseStatus}
              state={state.status}
              onSet={setCourseStatus}
            />
          </div>

          <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("publicationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="publicationdate"
                value={state.publicationdate}
                onChange={handleChange}
                min={
                  props.editdata && props.editdata.publication_date
                    ? ""
                    : moment(new Date()).format("yyyy-MM-D")
                }
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("expirationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="expirationdate"
                value={state.expirationdate}
                onChange={handleChange}
                min={moment(state.publicationdate).format("yyyy-MM-D")}
                required
              ></input>
            </div>
          </div>

          <div className="flexrows">
            <div className="monitoringfield">
              <span className="genreimg">
                <p>{translate("corelatedpartner") + ": "}</p>
              </span>
              {selectedlabels.map((item) => {
                return labelstags(item);
              })}
              {selectedlabels.length < 6 && (
                <span
                  className="customlabels"
                  onClick={() => setShowLabel(!showlabel)}
                >
                  +
                </span>
              )}
              <div
                className={
                  showlabel ? "dropdown-content show" : "dropdown-content"
                }
              >
                <input
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => setsearch(e.target.value)}
                />
                {labelSearchResult.map((item, index) => {
                  return (
                    <p onClick={() => addLabel(item, index)}>
                      {item.full_name}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flexrows">
            <Multiselect
              title={"category"}
              options={categorylist}
              labelField={"name"}
              valueField={"slug"}
              values={category}
              onChange={handleCategory}
              state={state.category}
            />
          </div>

          <div className="flexrows">
            <Multiselect
              title={"tags"}
              options={tagList}
              labelField={"tag_name"}
              valueField={"slug"}
              values={tags}
              onChange={setTags}
              state={state.tags}
            />
          </div>

          <div className="flexrows">
            <InputText
              title={"price"}
              placeholder={"Price..."}
              value={state.price}
              onChange={handleChange}
              name="price"
              type="number"
            />

            <InputText
              title={"discount_price"}
              placeholder={"Discount price..."}
              value={state.discount_price}
              onChange={handleChange}
              name="discount_price"
              type="number"
            />
          </div>

          <div className="flexrows">
            <div className="what_you_learn_container">
              <div className="what_you_learn_subcontainer">
                <InputText
                  title={"learn1"}
                  placeholder="Learn1..."
                  value={state.learn1}
                  name="learn1"
                  onChange={handleChange}
                  type="text"
                />

                <InputText
                  title={"learn2"}
                  placeholder="Learn2..."
                  value={state.learn2}
                  name="learn2"
                  onChange={handleChange}
                  type="text"
                />
              </div>

              <div className="what_you_learn_subcontainer">
                <InputText
                  title={"learn3"}
                  placeholder="Learn3..."
                  value={state.learn3}
                  name="learn3"
                  onChange={handleChange}
                  type="text"
                />

                <InputText
                  title={"learn4"}
                  placeholder="Learn4..."
                  value={state.learn4}
                  name="learn4"
                  onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="flexrows">
            <div className="checkbox-container">
              <Checkbox
                title="Show in shop ?"
                state={state.inShop}
                onChange={setInShop}
                name={"showShop"}
              />

              <Checkbox
                title="Is trending?"
                state={state.isTrending}
                onChange={setIsTrending}
                name={"showTrending"}
              />
            </div>
          </div>

          <div className="flexrows">
            <InputText
              title={"vimeo_link_trailer"}
              placeholder="vimeo link trailer..."
              value={state.trailer}
              name="trailer"
              onChange={handleChange}
              type="text"
            />

            <div className="flexrows">
              <GenericSelect
                title={"level"}
                options={levellist}
                labelField={"name"}
                valueField={"id"}
                value={level}
                state={state.level}
                onSet={setLevel}
              />
            </div>
          </div>

          <div className="uploadcourseheader">
            <p
              onClick={() => {
                setCourseClasses(!courseclasses);
              }}
            >
              {translate("createnewclass")}
            </p>
          </div>

          <table>
            <thead>
              <tr>
                <th style={{ width: "30%" }}>{"Class Name"}</th>
                <th style={{ width: "35%" }}>{"Description"}</th>
                <th style={{ width: "35%" }}>{"Publicate Date"}</th>
              </tr>
            </thead>

            <tbody>
              {selectedclasses.map((item, index) => {
                return (
                  <>
                    <tr key={index} onClick={() => setEditClass(index)}>
                      <td className="rowdata1">{item.name}</td>
                      <td className="rowdata2">{item.description}</td>
                      <td className="rowdata3">{item.publication_date}</td>
                    </tr>
                    {editclass === index && (
                      <div className="newcourseform" style={{ width: "235%" }}>
                        <form>
                          <div className="flexrows">
                            <p className="nameofclass">
                              {index + 1} {"Class order"}:
                            </p>
                            <input
                              className="courseforminput"
                              placeholder="Enter class order here...  "
                              name="order_number"
                              required
                              onChange={handleClassEdit}
                            ></input>
                            <p
                              className="closeiconcourse"
                              onClick={() => setEditClass(null)}
                            >
                              X
                            </p>
                          </div>

                          <div className="flexrows">
                            <p className="nameofclass">
                              {translate("nameofclass")}:
                            </p>
                            <input
                              className="courseforminput"
                              placeholder="Enter class name here...  "
                              name="name"
                              defaultValue={item.name}
                              required
                              onChange={handleClassEdit}
                            ></input>
                            {/* <p className="closeiconcourse" onClick={()=>setEditClass(null)}>X</p> */}
                          </div>

                          <div className="flexrows">
                            <div className="textfieldedituser">
                              <label>{translate("Vimeolink")}</label>
                              <span className="divider"></span>
                              <input
                                defaultValue={item.vimeolink}
                                type="text"
                                placeholder="Describe your video to let people know what it is…"
                                style={{ width: "60%" }}
                                required
                                name="vimeolink"
                                onChange={handleClassEdit}
                              ></input>
                            </div>

                            <div className="timedurationfield">
                              <div className="durationtime">
                                <label>{translate("duration")}:</label>
                                <input
                                  defaultValue={item.duration}
                                  className="textfield"
                                  type="text"
                                  required
                                  name="duration"
                                  onChange={handleClassEdit}
                                ></input>
                              </div>
                            </div>
                          </div>

                          <div className="textareaedituser">
                            <label>{translate("description")}</label>
                            <span className="divider"></span>
                            <textarea
                              defaultValue={item.description}
                              placeholder="Describe your video to let people know what it is…"
                              name="description"
                              onChange={handleClassEdit}
                              required
                            ></textarea>
                          </div>

                          <div className="textfieldeditcoursedate">
                            <label>{translate("publicationdate")}</label>
                            <span className="divider"></span>
                            <input
                              defaultValue={item.publication_date}
                              type="date"
                              name="publicationdate"
                              onChange={handleClassEdit}
                              required
                            ></input>
                          </div>

                          <div className="flexrows" style={{ margin: "2vw 0" }}>
                            <p className="resource">{translate("resource")}:</p>

                            {item.resource ? (
                              <div className="resource-filename">
                                <p> {item.resource}</p>

                                <span
                                  className="removeImageResources"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveImage("resource");
                                  }}
                                >
                                  x
                                </span>
                              </div>
                            ) : fileName && fileName?.index ===index? (
                              <div className="resource-filename">
                                <p> {fileName.name}</p>

                                <span
                                  className="removeImageResources"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveImage("filename");
                                  }}
                                >
                                  x
                                </span>
                              </div>
                            ) : (
                              <div className="resourceimageselector">
                                <label className="custom-file-upload">
                                  <input
                                    type="file"
                                    onChange={handleImage(index)}
                                    accept="image"
                                  ></input>
                                </label>
                              </div>
                            )}
                          </div>

                          <div style={{ display: "flex" }}>
                            <button
                              className="addnextclass"
                              onClick={onUploadClass}
                            >
                              {translate("updateclass")}
                            </button>
                            <button
                              className="addnextclass"
                              onClick={(e) => {
                                e.preventDefault();
                                deleteclass(item.id);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>

          {courseclasses && (
            <div className="newcourseform">
              {/* <form> */}

              <div className="flexrows">
                <p className="nameofclass">
                  {selectedclasses.length + 1} {"Class order"}:
                </p>
                <input
                  className="courseforminput"
                  placeholder="Enter class order here...  "
                  name="order_number"
                  required
                  onChange={handleClassChange}
                ></input>
                <p
                  className="closeiconcourse"
                  onClick={() => setCourseClasses(!courseclasses)}
                >
                  X
                </p>
              </div>

              <div className="flexrows">
                <p className="nameofclass">{translate("nameofclass")}:</p>
                <input
                  className="courseforminput"
                  placeholder="Enter class name here...  "
                  name="classname"
                  required
                  onChange={handleClassChange}
                ></input>
              </div>

              <div className="flexrows">
                <div className="textfieldedituser">
                  <label>{translate("Vimeolink")}</label>
                  <span className="divider"></span>
                  <input
                    type="text"
                    placeholder="Describe your video to let people know what it is…"
                    style={{ width: "60%" }}
                    required
                    name="vimeolink"
                    onChange={handleClassChange}
                  ></input>
                </div>

                <div className="timedurationfield">
                  <div className="durationtime">
                    <label>{translate("duration")}:</label>
                    <input
                      className="textfield"
                      type="text"
                      required
                      name="duration"
                      onChange={handleClassChange}
                    ></input>
                  </div>
                </div>
              </div>

              <div className="textareaedituser">
                <label>{translate("description")}</label>
                <span className="divider"></span>
                <textarea
                  placeholder="Describe your video to let people know what it is…"
                  name="description"
                  onChange={handleClassChange}
                  required
                ></textarea>
              </div>

              <div className="textfieldeditcoursedate">
                <label>{translate("publicationdate")}</label>
                <span className="divider"></span>
                <input
                  type="date"
                  // min={new Date()}
                  name="publicationdate"
                  min={moment(new Date()).format("yyyy-MM-D")}
                  onChange={handleClassChange}
                  required
                ></input>
              </div>
              <div className="flexrows" style={{ margin: "2vw 0" }}>
                <p className="resource">{translate("resource")}:</p>

                {fileName ? (
                  <div className="resource-filename">
                    <p> {fileName}</p>
                    <button
                      className="removeImageResources"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveImage("filename");
                      }}
                    >
                      x
                    </button>
                  </div>
                ) : (
                  <div className="resourceimageselector">
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        onChange={handleImage}
                        accept="image"
                      ></input>
                    </label>
                  </div>
                )}
              </div>

              <div style={{ display: "flex" }}>
                <button
                  className="addnextclass"
                  onClick={(e) => addMoreClass(e, "single")}
                >
                  {translate("addclass")} +
                </button>
                <button
                  className="addnextclass"
                  onClick={(e) => addMoreClass(e, "next")}
                >
                  {translate("addnextclass")} +
                </button>
              </div>
              {/* </form> */}
            </div>
          )}

          {editclass === null && (
            <button className="uploadbutton" type="submit" disabled={editclass}>
              {props.editdata ? translate("update") : translate("upload")}
            </button>
          )}
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default UploadCourses;
