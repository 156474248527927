import React, { useEffect, useState } from "react";
import "./tutor.scss";
import "./createtutorform/createpartnerform.scss";
import translate from "../../../utils/locale/language";
import {
  deleteMethodAuthenticated,
  getMethod,
} from "../../../utils/apis/services/api";
import urls from "../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { useClientSidePagination } from "../../../hooks/useClientSidePagination";
import Pagination from "../../../components/design-system/pagination";
import "../uploadcontent/uploadcontent.scss";
import RoyaltiesTable from "../royalties/royaltiesTable";
import HistoryTable from "../royalties/history/historytable";
import CreateTutorForm from "./createtutorform/createtutorform";

function Tutor() {
  const [partnerlist, setPartnerList] = useState([]);
  const [section, setSection] = useState(2);
  const [pageCount, setPageCount] = useState(null);
  const [editData, setEditData] = useState();
  const [loader, setLoader] = useState(false);
  const [royaltiesData, setRoyaltiesData] = useState([]);
  const [tutorId, setTutorID] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  const sectionCB = (value) => setSection(value);

  const editDataCB = (value) => setEditData(value);

  const {
    pageIndex,
    setPageIndex,
    maxPageNumberLimit,
    minPageNumberLimit,
    setmaxPageNumberLimit,
    setminPageNumberLimit,
  } = useClientSidePagination();

  const getPartnersList = async () => {
    setLoader(true);
    let partnerslist = await getMethod(urls.getalltutor + "?page=" + pageIndex);
    setPartnerList(partnerslist.data.results || []);
    setPageCount(partnerslist.data.page_count || null);
    setLoader(false);
  };

  const deletePartner = async (item) => {
    setLoader(true);
    let result = await deleteMethodAuthenticated(
      urls.getalltutor + item.id + "/"
    );
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      getPartnersList();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
    }
  };

  const EditUser = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.getalltutor + id + "/");
    setEditData(result.data);
    setSection(1);
    setLoader(false);
  };

  useEffect(() => {
    getPartnersList();
  }, [section, pageIndex]);

  const getRoyaltiesData = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.getalltutor + id + "/history/");
    setRoyaltiesData(result.data);
    setTutorID(id);
    setSection(3);
    setLoader(false);
  };

  return (
    <div className="partner uploadcontent">
      <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
        <div className=" userlisttable">
          {section === 1 ? (
            <CreateTutorForm
              setSection={() => setSection(2)}
              editData={editData}
              editDataCB={editDataCB}
              setEditData={setEditData}
            />
          ) : section === 2 ? (
            <>
              <div className="pageheading">
                <h2>{translate("partner")}</h2>{" "}
                <div className="mainbuttons">
                  <button className="create" onClick={() => setSection(1)}>
                    {translate("create")}
                  </button>
                  <button className="bulk" onClick={() => setSection(2)}>
                    {translate("bulk")}
                  </button>
                </div>
              </div>

              <table>
                <thead>
                  <tr>
                    <th style={{ width: "70%" }}>{"Partner"}</th>
                    <th style={{ width: "30%", border: 0 }}></th>
                  </tr>
                </thead>

                <tbody>
                  {partnerlist &&
                    partnerlist.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td
                            className="rowdata1"
                            onClick={() => EditUser(item.id)}
                          >
                            <img src={item.profile_image} alt="" />
                            <p>{item.full_name}</p>
                          </td>

                          <td className="rowdata4">
                            <button
                              className="btn-royalties"
                              onClick={() => getRoyaltiesData(item.id)}
                            >
                              royalties
                            </button>
                            <i
                              className="fas fa-trash-alt"
                              style={{ float: "right" }}
                              onClick={() =>
                                !item.first_time_login
                                  ? deletePartner(item)
                                  : toast.error(translate("partneractive"))
                              }
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <Pagination
                maxPageNumberLimit={maxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setmaxPageNumberLimit={setmaxPageNumberLimit}
                setminPageNumberLimit={setminPageNumberLimit}
                onPageChange={setPageIndex}
                pages={pageCount}
                currentPage={pageIndex}
                pageLimit={4}
              />
            </>
          ) : section === 3 ? (
            <RoyaltiesTable
              sectionCB={sectionCB}
              royalties={royaltiesData}
              setHistoryData={setHistoryData}
              type={'Partner'}
            />
          ) : (
            <HistoryTable
              sectionCB={sectionCB}
              tutorId={tutorId}
              historyData={historyData}
              type={'Partner'}
            />
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default Tutor;
