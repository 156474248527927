import React  from "react";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { deleteMethodAuthenticated } from "../../../../../utils/apis/services/api";
import { toast } from "react-toastify";

function TableCharts({ setLoader, contentlist, data, type, editChart }) {

  const deleteChart = async (id) => {
    setLoader(true);
    let result = await deleteMethodAuthenticated(urls.uploadchart + id + "/");
    toast.success(result.data.message);
    contentlist();
    setLoader(false);
  };

  return (
    <table className="contentlisttable">
      <thead>
        <tr>
          <th style={{ width: "20%" }}>{"Select All"}</th>
          <th style={{ width: "40%" }}>{type + " Name"}</th>
          <th style={{ width: "20%" }}>{"Author"}</th>
          <th style={{ width: "20%", border: 0 }}></th>
        </tr>
      </thead>

      <tbody>
        {data.map((item) => {
          return (
            <tr key={item.id}>
              <td
                className="rowdata1_charts"
                onClick={() => editChart(item.id)}
              >
                <img src={item.cover_image} alt="" />
              </td>
              <td
                className="rowdata2_charts"
                onClick={() => editChart(item.id)}
              >
                {item.chart_name}
              </td>
              <td
                className="rowdata3_charts"
                onClick={() => editChart(item.id)}
              >
                {item.author}
              </td>
              <td className="rowdata4_charts">
                <button
                  className="menu-link"
                  onClick={() => deleteChart(item.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TableCharts;
