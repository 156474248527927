import React, { useEffect, useState, useRef } from "react";
import "./createpartnerform.scss";
import translate from "../../../../utils/locale/language";
import Select from "react-dropdown-select";
import {
  getMethod,
  getMethodOpen,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../utils/apis/services/api";
import urls from "../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import Multiselect from "../../../../components/design-system/multiselect";
import GenericSelect from "../../../../components/design-system/select";
import InputText from "../../../../components/design-system/inputText";
import TxtArea from "../../../../components/design-system/txtArea";

const setRoyatiesFromEditData = (editData) => {
  let editSelectedRoyalties = [];
  editData?.royalties.forEach((elem) => {
    editSelectedRoyalties.push({
      slug: elem.type,
      value: elem.value,
    });
  });

  return editSelectedRoyalties;
};

const getSelectedRoyalties = (editData) => {
  if (!editData) return [];
  return setRoyatiesFromEditData(editData);
};

const setGenreData = (genre) => {
  if (genre) {
    return {
      genre_name: genre.name,
      slug: genre.slug,
      id: genre.id,
    };
  }
  return "";
};

const setSubgenreData = (subgenresList) => {
  let subgenres = [];
  subgenresList.forEach((elem) => {
    subgenres.push({
      genre_name: elem.name,
      slug: elem.slug,
      id: elem.id,
    });
  });
  return subgenres;
};

function CreateTutorForm({ setSection, editData, setEditData }) {
  const [genre, setGenre] = useState(
    editData?.genre ? setGenreData(editData?.genre) : ""
  );
  const [genrelist, setGenreList] = useState([]);
  const [state, setState] = useState({
    partnername: editData?.full_name || "",
    iban: editData?.iban || "",
    affiliationlink: editData?.affiliation || "",
    billaddress: editData?.billing_address || "",
    email: editData?.email || "",
    bio: editData?.bio || "",
    city: editData?.location || "",
    image: editData?.profile_image || "",
  });
  const [profileimage, setProfileimg] = useState(null);
  const [profile_image, setProfile_image] = useState(null);
  const [loader, setLoader] = useState(false);
  const [subgenres, setSubgenresdata] = useState(
    editData?.subgenres ? setSubgenreData(editData?.subgenres) : []
  );
  const [subgenrelist, setSubGenre] = useState([]);
  const [royaltydata, setRoyaltyData] = useState([]);
  const [royalties, setRoyalties] = useState(getSelectedRoyalties(editData));
  const [royalty, setStateRoyalty] = useState({
    slug: "",
    value: "",
  });
  const [newroyalty, setNewRoyalty] = useState(false);
  const [royaltyindex, setRoyaltyIndex] = useState(null);
  const imageRef = useRef();

  const handleImage = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setProfile_image(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setProfileimg(result.data.id);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClickImage = () => {
    imageRef.current.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (genre === "") {
      toast.error(translate("fillrequiredfields"));
    } else {
      setLoader(true);
      let body = {
        royalties: royalties,
        affiliation: state.affiliationlink,
        billing_address: state.billaddress,
        bio: state.bio,
        location: state.city,
        email: state.email.toLowerCase(),
        genre: genre.slug,
        full_name: state.partnername,
        subgenres:
          subgenres.length > 0 ? subgenres.map((elem) => elem.slug) : [],
        profile_image: profileimage,
        iban: state.iban,
      };

      let result = await postMethodAuthenticated(urls.getalltutor, body);

      if (result.data && !result.data.ErrorCode && result.status === 200) {
        toast.success(translate("invitationsent"));
        setLoader(false);
        setSection();
      } else {
        setLoader(false);
        toast.error(result.data.Error);
      }
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    setLoader(true);

    let body = {
      royalties: royalties,
      affiliation: state.affiliationlink,
      billing_address: state.billaddress,
      bio: state.bio,
      location: state.city,
      email: state.email.toLowerCase(),
      genre: genre.slug,
      full_name: state.partnername,
      subgenres: subgenres.length > 0 ? subgenres.map((elem) => elem.slug) : [],
      iban: state.iban,
    };
    let imageupadte = {
      profile_image: profileimage,
    };
    if (profileimage !== null) {
      Object.assign(body, imageupadte);
    }

    let result = await putMethodAuthenticated(
      urls.getalltutor + editData.id + "/",
      body
    );

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setLoader(false);
      setSection();
    } else {
      setLoader(false);
      toast.error(result.data.Error);
    }
  };

  const getGenreList = async () => {
    let genrelist = await getMethodOpen(urls.genrelist);
    setGenreList(genrelist && genrelist.data ? genrelist.data : []);
  };

  const getSubGenreList = async () => {
    if (genre?.slug) {
      let genrelist = await getMethodOpen(urls.getsubgenrelist + genre?.slug);
      setSubGenre(
        genrelist && genrelist.data ? setSubgenreData(genrelist.data) : []
      );
    }
  };

  const getRoyaltiesData = async () => {
    setLoader(true);
    let royaltydata = await getMethod(urls.getroyalitestypetutors);

    setRoyaltyData(royaltydata && royaltydata.data ? royaltydata.data : []);
    setLoader(false);
  };

  const handleSubgenre = (values) => {
    setSubgenresdata(
      values.map((item) => {
        return {
          genre_name: item.genre_name,
          slug: item.slug,
          id: item.id,
        };
      })
    );
  };

  const handleAmount = (e) => {
    let amount = e.target.value;

    setStateRoyalty((prevState) => ({
      ...prevState,
      value: parseFloat(amount),
    }));
  };

  const addNewRoyalty = async (e) => {
    e.preventDefault();

    if (royalty.action_type === "") {
      toast.error(translate("selectvalidcatogeory"));
    } else {
      if (!royalties.some((i) => i.slug.includes(royalty.slug))) {
        setRoyalties([...royalties, royalty]);

        setNewRoyalty(false);
        toast.success(translate("royaltyadded"));
      } else {
        toast.error(translate("catogeoryalreadyselected"));
      }
    }
  };

  const removeRoyalty = async (royalty) => {
    royalties.splice(royalties.indexOf(royalty), 1);
  };

  useEffect(() => {
    getGenreList();
    getRoyaltiesData();
    getSubGenreList();
  }, []);

  const setGenreCB = (values) => {
    setGenre(values);

    setSubGenre(values.subgenre);
  };

  const royaldataoptions =
    royalties.length === 0
      ? royaltydata
      : royaltydata.filter(
          (elem) => !royalties.some((selected) => selected.slug === elem.slug)
        );

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="pageheading">
        <h2>{translate("partner")}</h2>{" "}
        <p
          onClick={() => {
            setEditData(null);
            setSection(2);
          }}
        >
          {"< partner list"}
        </p>
      </div>
      <div>
        <h3 className="partnerheading">
          <b> {editData ? "Edit partner" : translate("createnewpartner")}</b>
        </h3>

        <div className="createpartner">
          <form onSubmit={editData ? handleEdit : handleSubmit}>
            <div className="createpartnerform">
              <div className="imageselector" onClick={handleClickImage}>
                <label className="custom-file-upload">
                  <input
                    ref={imageRef}
                    type="file"
                    onChange={handleImage}
                    accept="image"
                  ></input>
                </label>
                <img
                  className={
                    profile_image
                      ? "uploadedimage"
                      : editData
                      ? "uploadedimage"
                      : "uploadsymbol"
                  }
                  src={
                    profile_image
                      ? URL.createObjectURL(profile_image)
                      : editData
                      ? state.image
                      : "uploadicon"
                  }
                  alt=""
                />
              </div>

              <div className="edituserfields" style={{ width: "100%" }}>
                <InputText
                  title={"name"}
                  placeholder="Enter partner name"
                  value={state.partnername}
                  name="partnername"
                  onChange={handleChange}
                  type="text"
                />

                <InputText
                  title={"city"}
                  placeholder="Enter partner city"
                  value={state.city}
                  name="city"
                  onChange={handleChange}
                  type="text"
                />

                <InputText
                  title={"affiliation"}
                  placeholder="Add affliate link"
                  name="affiliationlink"
                  value={state.affiliationlink}
                  onChange={handleChange}
                  type="text"
                />

                <div className="flexrows">
                  <InputText
                    title={"iban"}
                    placeholder="For royalty payments"
                    name="iban"
                    onChange={handleChange}
                    value={state.iban}
                    type="text"
                  />

                  <InputText
                    title={"billing"}
                    placeholder="Add billing address..."
                    name="billaddress"
                    onChange={handleChange}
                    value={state.billaddress}
                    type="text"
                  />
                </div>

                <div className="flexrows">
                  <InputText
                    title={"email"}
                    placeholder="Partner E-mail"
                    name="email"
                    onChange={handleChange}
                    value={state.email}
                    type="text"
                  />

                  <GenericSelect
                    title={"maingenre"}
                    options={genrelist}
                    labelField={"genre_name"}
                    valueField={"slug"}
                    value={genre}
                    onSet={setGenreCB}
                  />
                </div>

                <div className="flexrows">
                  <Multiselect
                    title={"subgenre"}
                    options={subgenrelist}
                    labelField={"genre_name"}
                    valueField={"slug"}
                    value={subgenres}
                    onChange={handleSubgenre}
                    values={subgenres}
                  />
                </div>
                <div className="textareaedituser">
                  <TxtArea
                    title={"bio"}
                    placeholder="Enter something about yourself to describe you better..."
                    name="bio"
                    onChange={handleChange}
                    value={state.bio}
                  />
                </div>
              </div>
            </div>

            <div
              className="royaltiesheader"
              onClick={() => setNewRoyalty(!newroyalty)}
            >
              <h3>{translate("royalties")}</h3>

              <p>+ {translate("addnewroyalty")}</p>
            </div>

            {newroyalty && (
              <div className="addroyaltyinputs">
                <div className="genretagsroyalty">
                  <span className="genreimg">
                    <p>{translate("action") + ": "}</p>
                  </span>
                  <div>
                    <Select
                      options={royaldataoptions}
                      labelField={"name"}
                      valueField={"slug"}
                      onChange={(values) =>
                        setStateRoyalty((prevState) => ({
                          ...prevState,
                          slug: values[0].slug,
                        }))
                      }
                      className="ReactDropdownSelect"
                    />
                  </div>
                </div>

                <div
                  className="textfieldedituser"
                  style={{ margin: "1vw", height: "2vw" }}
                >
                  <label style={{ padding: "8px" }}>
                    {translate("amount")}
                  </label>
                  <span className="divider"></span>
                  <input
                    type="number"
                    placeholder="Enter amount (€/%)"
                    name="amount"
                    max={100}
                    onChange={handleAmount}
                    required
                  ></input>
                </div>

                <button
                  className="addroyaltybutton"
                  onClick={(e) => addNewRoyalty(e)}
                  disabled={royalties.length === 2}
                >
                  {translate("add")}
                </button>
              </div>
            )}

            <table>
              <thead>
                <tr>
                  <th style={{ width: "40%" }}>{"Action"}</th>
                  <th style={{ width: "40%" }}>{"Amount"}</th>
                  <th style={{ width: "20%" }}></th>
                </tr>
              </thead>

              <tbody>
                {royalties.map((item, index) => {
                  return (
                    <tr
                      key={item.slug}
                      className={index === royaltyindex && "selectedroyalty-"}
                      onClick={() => {
                        setRoyaltyIndex(index);
                      }}
                    >
                      <td className="rowdata1">{item.slug}</td>
                      <td className="rowdata2">{item.value}</td>
                      <td className="rowdata3">
                        <i
                          className="fas fa-trash-alt"
                          style={{ float: "right" }}
                          onClick={() => removeRoyalty(item)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <button className="sendinvitation">
              {editData ? "edit invitation" : translate("sendinvitation")}
            </button>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default CreateTutorForm;
