import React, { useState, useRef, useEffect } from "react";
import { getMethod } from "../../../utils/apis/services/api";
import UploadContests from "./components/uploadcontests/uploadcontests";
import ContentlistTable from "./components/contentlist/contentlist";
import urls from "../../../utils/apis/services/apiurls.json";
import translate from "../../../utils/locale/language";
import LoadingOverlay from "react-loading-overlay";
import InfoContest from "./components/infocontest/infocontest";
import { useClientSidePagination } from "../../../hooks/useClientSidePagination";
import Pagination from "../../../components/design-system/pagination";

const ContestManagment = ({ type, textAdd }) => {
  const section = useRef(translate(type));
  const [sectiontype, setSectionType] = useState(2);
  const [contentlist, setContentList] = useState([]);
  const [contstsInfo, setContstsInfo] = useState({
    data: null,
    id: null,
  });
  const [editdata, setEditData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(null);

  const {
    pageIndex,
    setPageIndex,
    maxPageNumberLimit,
    minPageNumberLimit,
    setmaxPageNumberLimit,
    setminPageNumberLimit,
  } = useClientSidePagination(sectiontype);

  const getContentList = async () => {
    const contentList = await getMethod(
      urls.uploadcontests + "?page=" + pageIndex
    );
    setContentList(contentList.data.results || []);
    setPageCount(
      contentList && contentList.data.page_count
        ? contentList.data.page_count
        : null
    );
  };

  const getInfoContestsId = async (id) => {
    const contstsInfo = await getMethod(
      urls.uploadcontests + id + "/applications/"
    );

    if (contstsInfo.data) {
      setContstsInfo({
        data: contstsInfo.data,
        id: id,
      });
      setSectionType(3);
    }
  };

  const edit = async (id) => {
    setLoader(true);
    let contentlist = await getMethod(urls.uploadcontests + id + "/");
    setEditData(contentlist.data);
    setSectionType(1);
    setLoader(false);
  };

  const rendersection = () => {
    return (
      <UploadContests
        setSection={() => setSectionType(2)}
        editdata={editdata}
        setedit={() => setEditData(null)}
      />
    );
  };

  const goBack = (section) => {
    setEditData(null);
    setSectionType(section);
  };

  useEffect(() => {
    getContentList();
  }, [sectiontype, pageIndex]);

  return (
    <div className="uploadcontent">
      <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
        {section.current !== null && (
          <div className="selectedsection">
            {
              <div className="uploadcontentsection">
                <div className="pageheading">
                  <h2>
                    {" "}
                    {sectiontype !== 2 && (
                      <p
                        onClick={() => goBack(2)}
                        style={{ cursor: "pointer" }}
                      >
                        {"<"}
                      </p>
                    )}{" "}
                    {section.current} list
                  </h2>
                  {sectiontype === 2 && (
                    <button className="add_new_tag" onClick={() => goBack(1)}>
                      {translate(textAdd)}
                    </button>
                  )}
                </div>
                <div className="userlisttable">
                  {sectiontype === 2 ? (
                    <>
                      <ContentlistTable
                        data={contentlist}
                        contentlist={() => getContentList()}
                        edit={(data) => edit(data)}
                        setLoader={(val) => setLoader(val)}
                        getInfo={getInfoContestsId}
                      />
                      <Pagination
                        maxPageNumberLimit={maxPageNumberLimit}
                        minPageNumberLimit={minPageNumberLimit}
                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                        setminPageNumberLimit={setminPageNumberLimit}
                        onPageChange={setPageIndex}
                        pages={pageCount}
                        currentPage={pageIndex}
                        pageLimit={4}
                      />
                    </>
                  ) : sectiontype === 3 ? (
                    <InfoContest
                      data={contstsInfo}
                      setSection={() => setSectionType(2)}
                    />
                  ) : (
                    rendersection()
                  )}
                </div>
              </div>
            }
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default ContestManagment;
