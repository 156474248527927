import React, { useEffect, useState } from "react";
import translate from "../../../../../utils/locale/language";
import LoadingOverlay from "react-loading-overlay";
import InputText from "../../../../../components/design-system/inputText";
import UploadImg from "../../../../../components/design-system/uploadImg";
import GenericSelect from "../../../../../components/design-system/select";
import {
  getMethodOpen,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import "./uploadheroes.scss";
import Checkbox from "../../../../../components/design-system/checkbox";
import { toast } from "react-toastify";

function UploadHeroes({ setSection, editdata, setedit }) {
  const [state, setState] = useState({
    title: editdata ? editdata.title : "",
    subtitle: editdata ? editdata.subtitle : "",
    url: editdata ? editdata.url : "",
    is_active: editdata ? editdata.is_active : null,
    image: editdata ? editdata.image : "",
    id_for_category: editdata ? editdata.id_for_category : null,
    id: editdata ? editdata.id : null,
    category: editdata ? editdata.category : "",
    button_text: editdata ? editdata.button_text : "",
  });

  const [loader, setLoader] = useState(false);
  const [coverimage, setCoverimg] = useState(null);
  const [cover_image, setCover_image] = useState(null);
  const [category, setCategory] = useState(null);
  const [categorylist, setCategoryList] = useState([]);
  const [active, setIsActive] = useState(
    state.is_active ? state.is_active : false
  );

  const getCatogeoryList = async () => {
    let catogeorylist = await getMethodOpen(urls.getcoursecategory);
    setCategoryList(
      catogeorylist && catogeorylist.data ? catogeorylist.data : []
    );
  };

  const handleCoverImage = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setCover_image(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setCoverimg(result.data.id);
    setLoader(false);
  };

  const handleChange = (type) => (e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  useEffect(() => {
    getCatogeoryList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let body = {
      title: state.title,
      subtitle: state.subtitle,
      url: state.url,
      image: coverimage ? coverimage.toString() : state.image,
      category: category.slug,
      id_for_category: category.id,
      is_active: active !== state.is_active ? active : state.is_active,
      button_text: state.button_text,
    };

    let result = await postMethodAuthenticated(urls.uploadhero, body);

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    let body = {
      title: state.title,
      subtitle: state.subtitle,
      url: state.url,
      category: category ? category.slug : state.category,
      id_for_category: category ? category.id : state.id_for_category,
      is_active: active !== state.is_active ? active : state.is_active,
      button_text: state.button_text,
    };

    if (cover_image) {
      body = { ...body, image: coverimage.toString() };
    }

    let result = await putMethodAuthenticated(
      urls.uploadhero + editdata.id + "/",
      body
    );

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setedit();
      setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="uploadcourses uploadhero">
        <h2 className="heading">Upload {translate("heroes")} </h2>
        <form onSubmit={editdata ? handleUpdate : handleSubmit}>
          <UploadImg
            editdata={state.image}
            cover_image={cover_image}
            handleCoverImage={handleCoverImage}
          />

          <InputText
            title={"title"}
            placeholder={"Hero title..."}
            value={state.title}
            onChange={handleChange("title")}
            name="herotitle"
            type="text"
          />

          <InputText
            title={"subtitle"}
            placeholder={"Hero subtitle..."}
            value={state.subtitle}
            onChange={handleChange("subtitle")}
            name="herotype"
            type="text"
          />

          <InputText
            title={"buttontext"}
            placeholder={"Hero button text..."}
            value={state.button_text}
            onChange={handleChange("button_text")}
            name="herotype"
            type="text"
          />

          <InputText
            title={"url"}
            placeholder={"Hero url..."}
            value={state.url}
            onChange={handleChange("url")}
            name="herotype"
            type="text"
          />

          <div className="flexrows">
            <div className="flexrows-content ">
              <GenericSelect
                title={"category"}
                options={categorylist}
                labelField={"name"}
                valueField={"id"}
                value={category}
                state={categorylist.find(
                  (category) => category.id === state.id_for_category
                )}
                onSet={setCategory}
                className="hero-category"
              />
            </div>
          </div>

          <div className="flexrows">
            <div className="flexrows-content ">
              <div className="checkbox-container">
                <Checkbox
                  title="Is active ?"
                  state={state.is_active}
                  onChange={setIsActive}
                  name={"isActive"}
                />
              </div>
            </div>
          </div>

          <button className="uploadbutton" type="submit">
            {editdata ? translate("update") : translate("upload")}
          </button>
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default UploadHeroes;
