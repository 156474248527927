import React from "react";
import "./contentlist.scss";
import urls from "../../../../../utils/apis/services/apiurls.json";
import {
  deleteMethodAuthenticated,
  patchMethodAuthenticatedWithBody,
} from "../../../../../utils/apis/services/api";
import { toast } from "react-toastify";

function ContentlistTable({
  data,
  setLoader,
  contentlist,
  edit,
  uploadTagsTable,
}) {
  const deleteTag = async (id) => {
    setLoader(true);
    let result = await deleteMethodAuthenticated(urls.uploadtags + id + "/");
    toast.success(result.data.message);
    contentlist();
    setLoader(false);
  };

  const hideTag = async (id, data) => {
    let body = {
      activation: data,
    };
    let result = await patchMethodAuthenticatedWithBody(
      urls.uploadtags + id + "/",
      body
    );
    toast.success(result.data.message);
    contentlist();
    setLoader(false);
  };

  return (
    <table className="contentlisttable">
      <thead>
        <tr>
          {uploadTagsTable.map((elem, index) => (
            <th style={{ width: elem.style }} key={index}>
              {elem.name}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={item.id}>
              <td className="rowdata1">
                <p onClick={() => edit(item)}>{item.name}</p>
              </td>
              <td className="rowdata2">
                <ul>
                  {item.list.map((elem) => (
                    <li>{elem.name}</li>
                  ))}
                </ul>
              </td>
              <td className="rowdata3">{item.is_active ? "Yes" : "No"}</td>
              <td className="rowdata4">
                <button
                  className="menu-link"
                  onClick={() =>
                    item.is_active
                      ? hideTag(item.id, false)
                      : hideTag(item.id, true)
                  }
                >
                  Row
                </button>
                <button
                  className="menu-link"
                  onClick={() => deleteTag(item.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default ContentlistTable;
