import React from "react";
import translate from "../../utils/locale/language"
import Select from "react-dropdown-select";

function Multiselect({title,options,labelField,valueField,state,values,onChange}) {

  return (
    <div className="addcourses genretags ">
      <span className="genreimg">
        <p>{translate(title) + " "}</p>
      </span>
      <div className="select-container dropdownselector">
        <Select
          multi
          options={options}
          labelField={labelField}
          valueField={valueField}
          values={state ? state : values}
          onChange={(data) => onChange(data)}
          className="ReactDropdownSelect"
        />
      </div>
    </div>
  );
}

export default Multiselect;
