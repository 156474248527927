import React, { useState } from "react";
import translate from "../../../../../utils/locale/language";

const DynamicInputs = ({ setState, val, title, type, inputName }) => {
  const [edit, setEdit] = useState(false);
  const [editTxt, setEditTxt] = useState("");

  const onEdit = (index) => (e) => {
    const value = e.target.value;

    if (value.length > 0) {
      setEditTxt(value);
    }
  };

  const onSave = (index) => (e) => {
    let inputs = [...val];
    inputs[index] = editTxt;

    setState(inputs);

    setEdit(false);
  };

  const onDelete = (value) => {
    let inputs = val.filter((elem) => elem !== value);

    setState(inputs);
  };

  const addRow = () => {
    const abc = [...val, ""];
    setState(abc);
  };

  return (
    <div className="resources ">
      <div className="resources-header ">
        <p
          className="uploadcourseheader"
          onClick={() => {
            addRow();
          }}
        >
          {translate(title)} +
        </p>

        <div className="resources-inputs">
          {val?.length > 0 &&
            val?.map((elem, index) => (
              <div key={elem} className="input-state-container">
                <span>
                  {index + 1} {inputName}
                </span>

                {edit ? (
                  <>
                    <input
                      type="text"
                      defaultValue={elem}
                      onChange={onEdit(index)}
                      className="input-state-contest"
                    />
                    <div className="btn-input-state">
                      <span
                        className="btn-input-state-edit"
                        onClick={onSave(index)}
                      >
                        save
                      </span>
                      <span
                        className="btn-input-state-delete"
                        onClick={() => setEdit(false)}
                      >
                        x
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="input-state-name">{elem}</span>
                    <div className="btn-input-state">
                      <span
                        className="btn-input-state-edit"
                        onClick={() => setEdit(!edit)}
                      >
                        edit
                      </span>
                      <span
                        className="btn-input-state-delete"
                        onClick={() => onDelete(elem)}
                      >
                        x
                      </span>
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DynamicInputs;
