import React, { useEffect, useState, useRef } from "react";
import "./uploadcontent.scss";
import translate from "../../../utils/locale/language";
import UploadCourses from "./components/uploadcourses/uploadcourses";
import ContentlistTable from "./components/contentlist/contentlist";
import { getMethod } from "../../../utils/apis/services/api";
import urls from "../../../utils/apis/services/apiurls.json";
import LoadingOverlay from "react-loading-overlay";
import Uploadsample from "./components/uploadsample/uploadsample";
import Uploadchart from "./components/uploadchart/uploadchart";
import UploadBoundle from "./components/uploadboundle/UploadBoundle";
import Pagination from "../../../components/design-system/pagination";
import { useClientSidePagination } from "../../../hooks/useClientSidePagination";

function Uploadcontent({ type }) {
  const section = useRef(translate(type || "course"));

  const [searchterm, setsearch] = useState("");
  const [pageCount, setPageCount] = useState(null);
  const [sectiontype, setSectionType] = useState(2);
  const [contentlist, setContentList] = useState([]);
  const [editdata, setEditData] = useState(null);
  const [loader, setLoader] = useState(false);

  const {
    pageIndex,
    setPageIndex,
    maxPageNumberLimit,
    minPageNumberLimit,
    setmaxPageNumberLimit,
    setminPageNumberLimit,
  } = useClientSidePagination(sectiontype);

  const getContentList = async () => {
    let contentlist = await getMethod(
      type === "charts"
        ? urls.uploadchart + "?page=" + pageIndex + "&query=" + searchterm
        : type === "course"
        ? urls.uploadcontent + "?page=" + pageIndex + "&query=" + searchterm
        : type === "boundle"
        ? urls.uploadboundle + "?page=" + pageIndex + "&query=" + searchterm
        : urls.samplecreate + "?page=" + pageIndex + "&query=" + searchterm
    );

    setContentList(
      contentlist && contentlist.data.results ? contentlist.data.results : []
    );
    setPageCount(
      contentlist && contentlist.data.page_count
        ? contentlist.data.page_count
        : null
    );
  };

  useEffect(() => {
    getContentList();
  }, [sectiontype, pageIndex, searchterm]);

  const Edituser = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.uploadcontent + id + "/");
    setEditData(result.data);
    setSectionType(1);
    setLoader(false);
  };

  const Editboundle = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.uploadboundle + id + "/");
    setEditData(result.data);
    setSectionType(1);
    setLoader(false);
  };

  const EditChart = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.uploadchart + id + "/");
    setEditData(result.data);
    setSectionType(1);
    setLoader(false);
  };

  const EditSample = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.sampleid + id + "/");
    setEditData(result.data);
    setSectionType(1);
    setLoader(false);
  };

  const rendersection = (val) => {
    switch (val) {
      case "course":
        return (
          <UploadCourses
            setSection={() => setSectionType(2)}
            editdata={editdata}
            setedit={() => setEditData(null)}
          />
        );

      case "boundle":
        return (
          <UploadBoundle
            setSection={() => setSectionType(2)}
            editdata={editdata}
            setedit={() => setEditData(null)}
          />
        );

      case "samplelibrary":
        return (
          <Uploadsample
            setSection={() => setSectionType(2)}
            editdata={editdata}
            setedit={() => setEditData(null)}
          />
        );

      case "charts":
        return (
          <Uploadchart
            setSection={() => setSectionType(2)}
            editdata={editdata}
            setedit={() => setEditData(null)}
          />
        );

      default:
        return;
    }
  };

  return (
    <div className="uploadcontent">
      <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
        {section.current !== null && (
          <div className="selectedsection">
            {
              <div className="uploadcontentsection">
                <div className="pageheading">
                  <h2>
                    {" "}
                    {sectiontype === 1 && (
                      <p
                        onClick={() => {
                          setEditData(null);
                          setSectionType(2);
                        }}
                      >
                        {"<"}
                      </p>
                    )}{" "}
                    {section.current} list
                  </h2>

                  <div className="mainbuttons">
                    <button
                      className="create"
                      onClick={() => {
                        setSectionType(1);
                      }}
                    >
                      {translate("create")}
                    </button>
                    <button
                      className="bulk"
                      onClick={() => {
                        setSectionType(2);
                        setEditData(null);
                      }}
                    >
                      {translate("bulk")}
                    </button>
                  </div>
                </div>

                <div className="userlisttable">
                  {sectiontype === 2 ? (
                    <>
                      <div className="search-box">
                        <p>
                          Search by {type === "course" ? "tutor name" : "name"}
                        </p>
                        <input
                          placeholder="search..."
                          className="search"
                          onChange={(e) => setsearch(e.target.value)}
                        />
                      </div>

                      <ContentlistTable
                        type={section.current}
                        data={contentlist}
                        contentlist={() => getContentList()}
                        editcourse={(id) => Edituser(id)}
                        editboundle={(id) => Editboundle(id)}
                        editSample={(id) => EditSample(id)}
                        setLoader={(val) => setLoader(val)}
                        setSection={() => setSectionType(2)}
                        editChart={(id) => EditChart(id)}
                      />
                      <Pagination
                        maxPageNumberLimit={maxPageNumberLimit}
                        minPageNumberLimit={minPageNumberLimit}
                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                        setminPageNumberLimit={setminPageNumberLimit}
                        onPageChange={setPageIndex}
                        pages={pageCount}
                        currentPage={pageIndex}
                        pageLimit={4}
                      />
                    </>
                  ) : (
                    rendersection(type)
                  )}
                </div>
              </div>
            }
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
}

export default Uploadcontent;
