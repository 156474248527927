import React, { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./navbar.scss";
import translate, {
  getCurrentLocale,
  setLocale,
} from "../../utils/locale/language";
import seedjlogo from "../../assets/images/seedjlogo.png";
import {  removeUserSession } from "../../utils/common";

function Navbar(props) {
  const history = useHistory();

  useEffect(() => {
    let lang = window.localStorage.getItem("lang");
    setLocale(lang);
  }, []);

  const changeLanguage = (lan) => {
    setLocale(lan);
    alert(getCurrentLocale());
    window.localStorage.setItem("lang", lan);
    history.push(history.location.pathname);
  };

  return (
    <div className="navbar">
      <div className="nav-title">
        <img style={{ width: "8vw" }} src={seedjlogo} alt="" />
      </div>
      <ul
        className="nav-item"
        style={{ width: props.type === "agency" && "20%" }}
      >
        <span style={{ display: "flex", flexDirection: "row", height: 20 }}>
          <p
            onClick={() =>
              props.langchange ? props.langchange("it") : changeLanguage("it")
            }
            className={
              getCurrentLocale() === "it" ? "lang_selected" : "lang_notselected"
            }
          >
            IT
          </p>
          <span className="lang_divider" />
          <p
            onClick={() =>
              props.langchange ? props.langchange("sp") : changeLanguage("sp")
            }
            className={
              getCurrentLocale() === "sp" ? "lang_selected" : "lang_notselected"
            }
          >
            ES
          </p>
          <span className="lang_divider" />
          <p
            onClick={() =>
              props.langchange ? props.langchange("en") : changeLanguage("en")
            }
            className={
              getCurrentLocale() === "en" ? "lang_selected" : "lang_notselected"
            }
          >
            EN
          </p>
        </span>

        <li>
          <NavLink
            exact
            to={() => "/home"}
            className="nav-link "
            activeClassName="link-active"
          >
            {translate("admin")}
          </NavLink>
        </li>

        <li
          onClick={() => {
            removeUserSession();
            window.location.reload();
          }}
        >
          <p style={{ margin: 0 }} className="nav-link">
            {translate("logout")}
          </p>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
