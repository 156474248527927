import React, { useState } from "react";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { getMethod } from "../../../../../utils/apis/services/api";
import { toast } from "react-toastify";

function TableCourses({
  type,
  data,
  editcourse,
  setLoader,
  contentlist,
  setSection
}) {
  const [hover, setHover] = useState(null);

  const deleteCourse = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.uploadcontent + id + "/?action=delete");
    toast.success(result.data.message);
    contentlist();
    setLoader(false);
  };

  const hideCourse = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.uploadcontent + id + "/?action=hide");
    toast.success(result.data.message);
    setSection();
    setLoader(false);
  };

  return (
    <table className="contentlisttable">
      <thead>
        <tr>
          <th style={{ width: "20%" }}>{"Select All"}</th>
          <th style={{ width: "25%" }}>{type + " Name"}</th>
          <th style={{ width: "25%" }}>{"Author"}</th>
          <th style={{ width: "10%" }}>{"Sales"}</th>
          <th style={{ width: "20%", border: 0 }}>{"Amount "}</th>
        </tr>
      </thead>

      <tbody>
        {data
          .sort((p1, p2) =>
            p1.tutor.full_name > p2.tutor.full_name
              ? 1
              : p1.tutor.full_name < p2.tutor.full_name
              ? -1
              : 0
          )
          .map((item) => {
            return (
              <tr key={item.id}>
                <td
                  className="rowdata1  rowdata1_courses"
                  onClick={() => editcourse(item.id)}
                >
                  <img src={item.course_image} alt="" />
                </td>
                <td
                  className="rowdata2 rowdata2_courses"
                  onClick={() => editcourse(item.id)}
                >
                  {item.name}
                </td>
                <td
                  className="rowdata3 rowdata3_courses"
                  onClick={() => editcourse(item.id)}
                >
                  {item.tutor.full_name}
                </td>

                <td
                  className="rowdata4 rowdata4_courses"
                  onClick={() => editcourse(item.id)}
                >
                  {item.sales}
                </td>
                <td
                  className="rowdata5 rowdata5_courses"
                  onMouseEnter={() => setHover(item.id)}
                  onMouseLeave={() => setHover(null)}
                >
                  {item.amount}&euro; <i className="far fa-ellipsis-h"></i>
                  {hover === item.id && (
                    <ul className="hovercontents">
                      <li
                        className="menu-link"
                        onClick={() => hideCourse(item.id)}
                      >
                        Hide
                      </li>
                      <li
                        className="menu-link"
                        onClick={() => deleteCourse(item.id)}
                      >
                        Delete
                      </li>
                    </ul>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

export default TableCourses;
