import React from "react";
import translate from "../../utils/locale/language"

function InputText({title,value,onChange,placeholder,name,className,type}) {
  return (
    <div className={`textfieldedituser ${className}`}>
      <label>{translate(title)}</label>
      <span className="divider"></span>
      <input
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        type={type}
        required
      />
    </div>
  );
}

export default InputText;
