import React from "react";
import "./contentlist.scss";
import { deleteMethodAuthenticated } from "../../../../../utils/apis/services/api";
import { toast } from "react-toastify";
import urls from "../../../../../utils/apis/services/apiurls.json";

function ContentlistTable({ type, data, setLoader, contentlist, edit }) {
  const deleteSinglePath = async (id) => {
    setLoader(true);
    let result = await deleteMethodAuthenticated(
      urls.uploadsinglepath + id + "/"
    );
    toast.success(result.data.message);
    contentlist();
    setLoader(false);
  };

  return (
    <table className="contentlisttable">
      <thead>
        <tr>
          <th style={{ width: "60%" }}>{type + " Name"}</th>
          <th style={{ width: "40%", border: 0 }}></th>
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={item.id}>
              <td className=" rowdata1_pathmanagment rowdata1">
                <p onClick={() => edit(item.id)}>{item.name}</p>
              </td>
              <td className=" rowdata2_pathmanagment rowdata2">
                <button className="menu-link" onClick={() => edit(item.id)}>
                  Edit
                </button>
                <button
                  className="menu-link"
                  onClick={() => deleteSinglePath(item.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ContentlistTable;
