import React, { useState, useEffect } from "react";
import translate from "../../../../../utils/locale/language";
import urls from "../../../../../utils/apis/services/apiurls.json";
import {
  getMethod,
  getMethodOpen,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import LoadingOverlay from "react-loading-overlay";
import "./uploadsinglepath.scss";
import { getToken } from "../../../../../utils/common";
import { parse } from "papaparse";
import { toast } from "react-toastify";
import Multiselect from "../../../../../components/design-system/multiselect";
import GenericSelect from "../../../../../components/design-system/select";
import InputText from "../../../../../components/design-system/inputText";
import { CSVLink } from "react-csv";
const DJ_PRODUCER = "dj-producer";

function Uploadsinglepath({ setSection, editdata, setedit }) {
  const [state, setState] = useState({
    name: editdata ? editdata.name : "",
    profile: editdata ? editdata.profile : "",
    level: editdata ? editdata.level : " ",
    goal: editdata ? editdata.goal : null,
    paypalplans: editdata ? editdata.paypalplans : null,
    courses: editdata ? editdata.courses : null,
    producer_courses: editdata ? editdata.producer_courses : null,
    achievements: editdata ? editdata.achievements : [],
    dj_courses: editdata && editdata.producer_courses ? editdata.courses : null,
  });

  const [paypal, setPaypal] = useState([]);
  const [paypallist, setPaypalList] = useState([]);
  const [goals, setGoals] = useState([]);
  const [goallist, setGoalList] = useState([]);
  const [levellist, setLevelList] = useState([]);
  const [level, setLevel] = useState(null);
  const [profileRequestedList, setProfileRequestedList] = useState([]);
  const [profileRequested, setProfileRequested] = useState(
    state.profile ? state.profile : null
  );
  const [courses, setCourses] = useState([]);
  const [courseslist, setCoursesList] = useState([]);
  const [coursesDJ, setCoursesDJ] = useState([]);
  const [courseslistDJ, setCoursesListDJ] = useState([]);
  const [coursesProducer, setCoursesProducer] = useState([]);
  const [courseslistPoducer, setCoursesListProducer] = useState([]);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState({
    name: "",
    data: null,
  });

  const getProfileRequestedList = async () => {
    let profileRequestedList = await getMethod(urls.getpathprofile);
    setProfileRequestedList(
      profileRequestedList && profileRequestedList.data
        ? profileRequestedList.data
        : []
    );
  };

  const getPypalList = async () => {
    let paypalids = await getMethod(urls.getpaypalsubscriptionplan, getToken());
    setPaypalList(paypalids && paypalids.data ? paypalids.data : []);
  };

  const getGoalList = async () => {
    let goallist = [];
    if (profileRequested !== null) {
      goallist = await getMethodOpen(urls.getpathgoal + profileRequested.slug);
    } else if (state.profile !== null) {
      goallist = await getMethodOpen(urls.getpathgoal + state.profile.slug);
      setGoalList(goallist && goallist.data ? goallist.data : []);
    }

    setGoalList(goallist && goallist.data ? goallist.data : []);
  };

  const getLevelList = async () => {
    let levellist = await getMethod(urls.getpathlevel);

    setLevelList(levellist && levellist.data ? levellist.data : []);
  };

  const getCourseList = async () => {
    let courselist = await getMethod(urls.allcourses);

    if (courselist && courselist.data) {
      setCoursesList(
        courselist.data.sort((a, b) => (a.name < b.name ? -1 : 1))
      );

      setCoursesListDJ(
        courselist.data
          .filter((course) =>
            course.category.find(
              (courseCategory) => courseCategory.slug === "djing"
            )
          )
          .sort((a, b) => (a.name < b.name ? -1 : 1))
      );
      setCoursesListProducer(
        courselist.data
          .filter((course) =>
            course.category.find(
              (courseCategory) => courseCategory.slug === "producing"
            )
          )
          .sort((a, b) => (a.name < b.name ? -1 : 1))
      );
    }
  };

  const handleChange = (type) => (e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  useEffect(() => {
    getLevelList();
    getCourseList();
    getProfileRequestedList();
    getPypalList();
  }, []);

  useEffect(() => {
    getGoalList();
  }, [profileRequested]);

  const handleFile = (event) => {
    parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        setFile({
          name: event.target.files[0].name,
          data: result.data,
        });
      },
    });
  };

  const handleDropFile = (event) => {
    event.preventDefault();
    Array.from(event.dataTransfer.files).map(async (file) => {
      let text = await file.text();
      let result = parse(text, { header: true });
      setFile({
        name: file.name,
        data: result,
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let body = {};
    if (profileRequested.slug === DJ_PRODUCER) {
      body = {
        name: state.name,
        profile: profileRequested.slug,
        level: level.slug,
        goal: goals.map((item) => item.slug),
        courses: coursesDJ.map((item) => item.id),
        producer_courses: coursesProducer.map((item) => item.id),
        paypalplans: paypal.map((item) => item.id),
        achievements: file,
      };
    } else {
      body = {
        name: state.name,
        profile: profileRequested.slug,
        level: level.slug,
        goal: goals.map((item) => item.slug),
        courses: courses.map((item) => item.id),
        paypalplans: paypal.map((item) => item.id),
        achievements: file,
        producer_courses: null,
      };
    }

    let result = await postMethodAuthenticated(urls.uploadsinglepath, body);
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setSection(2);
      setLoader(false);
    } else {
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoader(true);

    const djProducer =
      state.profile.slug === DJ_PRODUCER ||
      profileRequested.slug === DJ_PRODUCER;

    let body = {};

    if (djProducer) {
      body = {
        name: state.name,
        profile: profileRequested ? profileRequested.slug : state.profile.slug,
        level: level ? level.slug : state.level.slug,
        goal:
          goals.length > 0
            ? goals.map((item) => item.slug)
            : state.goal.map((item) => item.slug),
        courses:
          coursesDJ.length > 0
            ? coursesDJ.map((item) => item.id)
            : state.courses.map((item) => item.id),
        producer_courses:
          coursesProducer.length > 0
            ? coursesProducer.map((item) => item.id)
            : state.producer_courses.map((item) => item.id),
        paypalplans:
          paypal.length > 0
            ? paypal.map((item) => item.plan_id)
            : state.paypalplans.map((item) => item.id),
      };
    } else {
      body = {
        name: state.name,
        profile: profileRequested ? profileRequested.slug : state.profile.slug,
        level: level ? level.slug : state.level.slug,
        goal:
          goals.length > 0
            ? goals.map((item) => item.slug)
            : state.goal.map((item) => item.slug),
        courses:
          courses.length > 0
            ? courses.map((item) => item.id)
            : state.courses.map((item) => item.id),
        paypalplans:
          paypal.length > 0
            ? paypal.map((item) => item.plan_id)
            : state.paypalplans.map((item) => item.id),

        producer_courses: null,
      };
    }

    if (file.data) {
      body = { ...body, achievements: file };
    }

    let result = await putMethodAuthenticated(
      urls.uploadsinglepath + editdata.id + "/",
      body
    );

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      setLoader(false);
    } else {
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  const isDJProducer =
    (state.profile && state.profile.slug === DJ_PRODUCER) ||
    (profileRequested && profileRequested.slug === DJ_PRODUCER);

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="uploadcourses uploadsinglepath">
        <h2 className="heading">{translate("singlepath")}</h2>
        <form onSubmit={editdata ? handleUpdate : handleSubmit}>
          <InputText
            title={"name"}
            value={state.name}
            onChange={handleChange("name")}
            placeholder="Write the name of path here..."
            name="pathname"
          />

          <div className="flexrows">
            <div className="flexrows-content">
              <GenericSelect
                title={"profilerequested"}
                options={profileRequestedList}
                labelField={"name"}
                valueField={"id"}
                value={profileRequested}
                state={state.profile}
                onSet={setProfileRequested}
              />

              <GenericSelect
                title={"level"}
                options={levellist}
                labelField={"name"}
                valueField={"id"}
                value={level}
                state={state.level}
                onSet={setLevel}
              />
            </div>
          </div>

          <div className="flexrows">
            <div className="flexrows-content">
              <Multiselect
                title={"goal"}
                options={goallist}
                labelField={"name"}
                valueField={"id"}
                values={goals}
                onChange={setGoals}
                state={state.goal}
              />
            </div>
          </div>

          <div className="flexrows">
            <div className="flexrows-content-courses">
              {isDJProducer ? (
                <>
                  <Multiselect
                    title={"courseDJ"}
                    options={courseslistDJ}
                    labelField={"name"}
                    valueField={"id"}
                    values={coursesDJ}
                    onChange={setCoursesDJ}
                    state={state.dj_courses}
                  />

                  <Multiselect
                    title={"courseProducer"}
                    options={courseslistPoducer}
                    labelField={"name"}
                    valueField={"id"}
                    values={coursesProducer}
                    onChange={setCoursesProducer}
                    state={state.producer_courses}
                  />
                </>
              ) : (
                <Multiselect
                  title={"course"}
                  options={courseslist}
                  labelField={"name"}
                  valueField={"id"}
                  values={courses}
                  onChange={setCourses}
                  state={state.courses}
                />
              )}
            </div>
          </div>

          <div className="flexrows">
            <div className="flexrows-content">
              <Multiselect
                title={"paypal"}
                options={paypallist}
                labelField={"plan_id"}
                valueField={"plan_id"}
                values={paypal}
                onChange={setPaypal}
                state={state.paypalplans}
              />
            </div>
          </div>

          {state.achievements.length > 0 ? (
            <div className="flexrows">
              <div className="genretags container-csv-file ">
                <CSVLink data={state.achievements}>Download me</CSVLink>
                <p>or</p>
                <span
                  className="btn-delete-file"
                  onClick={() => setState({ ...state, achievements: [] })}
                >
                  Delete file
                </span>
              </div>
            </div>
          ) : (
            <div className="flexrows">
              <div
                className="genretags container-csv-file "
                onDragOver={(event) => {
                  event.preventDefault();
                }}
                onDrop={handleDropFile}
              >
                {!file.data ? (
                  <div className="upload-csv-file">
                    <span className="genreimg">
                      <p>Drop file here</p>
                    </span>
                    <p>or</p>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={handleFile}
                    />
                  </div>
                ) : (
                  <div className="delete-csv-file">
                    <p>{file.name} uploaded!</p>
                    <button
                      className="btn-delete-file"
                      onClick={() => setFile({ name: "", data: null })}
                    >
                      Delete file
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          <button className="uploadbutton" type="submit">
            {editdata ? translate("update") : translate("upload")}
          </button>
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default Uploadsinglepath;
