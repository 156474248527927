import React, { useState, useEffect } from "react";
import urls from "../../../../../utils/apis/services/apiurls.json";
import {
  getMethod,
  getMethodOpen,
  postMethodAuthenticated,
  putMethodAuthenticated,
} from "../../../../../utils/apis/services/api";
import translate from "../../../../../utils/locale/language";
import "../uploadcourses/uploadcourses.scss";
import Select from "react-dropdown-select";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import "./uploadbundles.scss";
import { toast } from "react-toastify";

function UploadBoundle(props) {
  const languages = [
    {
      name: "Spanish",
      slug: "sp",
    },
    {
      name: "English",
      slug: "en",
    },
    {
      name: "Italian",
      slug: "it",
    },
    {
      name: "Dutch",
      slug: "nl",
    },
    {
      name: "French",
      slug: "fr",
    },
  ];
  const [state, setState] = useState({
    boundlename: props.editdata ? props.editdata.name : "",
    coverimage: props.editdata ? props.editdata.course_image : "",
    description: props.editdata ? props.editdata.description : "",
    language: props.editdata ? props.editdata.language : "",
    subtitle: props.editdata ? props.editdata.subtitle : "",
    duration: props.editdata ? props.editdata.duration : "",
    publicationdate: props.editdata ? props.editdata.publication_date : null,
    expirationdate: props.editdata ? props.editdata.expiration_date : null,
    price: props.editdata ? props.editdata.price : null,
    discount_price: props.editdata ? props.editdata.discount_price : null,
    learn1: props.editdata ? props.editdata.learn1 : "",
    learn2: props.editdata ? props.editdata.learn2 : "",
    learn3: props.editdata ? props.editdata.learn3 : "",
    learn4: props.editdata ? props.editdata.learn4 : "",
    courseStatus: props.editdata ? props.editdata.status : "",
    satisfaction: props.editdata ? props.editdata.satisfaction : null,
    courses: props.editdata ? props.editdata.courses : null,
    level: props.editdata ? props.editdata.level : "",
    inShop: props.editdata ? props.editdata.in_shop : null,
  });
  const [inShop, setInShop] = useState(props.editdata ? state.inShop : false);
  const [coverimage, setCoverimg] = useState(null);
  const [cover_image, setCover_image] = useState(null);
  const [courses, setCourses] = useState([]);
  const [courseslist, setCoursesList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [levellist, setLevelList] = useState([]);
  const [level, setLevel] = useState(null);
  const [courseStatusList, setCourseStatusList] = useState([]);
  const [courseStatus, setCourseStatus] = useState(null);

  const getCourseList = async () => {
    let courselist = await getMethod(urls.allcourses);
    setCoursesList(courselist && courselist.data ? courselist.data : []);
  };

  const getCourseStatusList = async () => {
    let courseList = await getMethodOpen(urls.coursestatuslist);
    setCourseStatusList(courseList && courseList.data ? courseList.data : []);
  };

  const getLevelList = async () => {
    let levellist = await getMethod(urls.getlevel);
    setLevelList(levellist && levellist.data ? levellist.data : []);
  };

  const handleCoverImage = async (e) => {
    setLoader(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setCover_image(e.target.files[0]);
    let result = await postMethodAuthenticated(urls.fileupload, formData);
    setCoverimg(result.data.id);
    setLoader(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getCourseList();
    getLevelList();
    getCourseStatusList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = {
      name: state.boundlename,
      course_image: coverimage.toString(),
      description: state.description,
      subtitle: state.subtitle,
      language: state.language.slug,
      duration: state.duration,
      status: courseStatus.slug,
      publication_date: state.publicationdate,
      expiration_date: state.expirationdate,
      price: state.price,
      discount_price: state.discount_price,
      learn1: state.learn1,
      learn2: state.learn2,
      learn3: state.learn3,
      learn4: state.learn4,
      in_shop: inShop,
      level: level.slug,
      courses: courses.map((item) => item.id),
      satisfaction: state.satisfaction,
    };
    setLoader(true);
    let result = await postMethodAuthenticated(urls.uploadboundle, body);

    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      props.setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };


  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoader(true);
    let body = {
      name: state.boundlename,
      description: state.description,
      subtitle: state.subtitle,
      language: state.language.slug,
      duration: state.duration,
      status: courseStatus ? courseStatus.slug : state.courseStatus.slug,
      publication_date: state.publicationdate,
      expiration_date: state.expirationdate,
      price: state.price,
      discount_price: state.discount_price,
      learn1: state.learn1,
      learn2: state.learn2,
      learn3: state.learn3,
      learn4: state.learn4,
      in_shop: inShop !== state.inShop ? inShop : state.inShop,
      level: level ? level.slug : state.level.slug,
      courses:
        courses.length > 0
          ? courses.map((item) => item.id)
          : state.courses.map((item) => item.id),
      satisfaction: state.satisfaction,
    };

    if (coverimage !== null) {
      Object.assign(body, { course_image: coverimage });
    }
    let result = await putMethodAuthenticated(
      urls.uploadboundle + props.editdata.id + "/",
      body
    );
    if (result.data && !result.data.ErrorCode && result.status === 200) {
      toast.success(result.data.message);
      props.setedit();
      props.setSection();
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(result.data.Error);
      setLoader(false);
    }
  };

  return (
    <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
      <div className="uploadcourses uploadbundle">
        <h2 className="heading">{translate("boundle")}</h2>
        <form onSubmit={props.editdata ? handleUpdate : handleSubmit}>
          <div className="coverimageselector">
            <label className="custom-file-upload">
              <input
                type="file"
                onChange={handleCoverImage}
                accept="image"
              ></input>
              <img
                class={
                  cover_image
                    ? "uploadedimage"
                    : props.editdata
                    ? "uploadedimage"
                    : "uploadsymbol"
                }
                src={
                  cover_image
                    ? URL.createObjectURL(cover_image)
                    : props.editdata
                    ? props.editdata.course_image
                    : "uploadicon"
                }
                alt=""
              />
              {cover_image || props.editdata ? "" : <p>Select Course Image</p>}
            </label>
          </div>

          <div className="textfieldedituser">
            <label>{translate("name")}</label>
            <span className="divider"></span>
            <input
              placeholder="Write the name of boundle here..."
              value={state.boundlename}
              name="boundlename"
              onChange={handleChange}
              required
            ></input>
          </div>

          <div className="textareaedituser">
            <label>{translate("description")}</label>
            <span className="divider"></span>
            <textarea
              placeholder="Describe your video to let people know what it is…"
              value={state.description}
              name="description"
              onChange={handleChange}
              required
            ></textarea>
          </div>

           <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("language")}</label>
              <span className="divider"></span>
              <div className="dropdownselector">
                <Select
                  options={languages}
                  values={
                    props.editdata
                      ? [props.editdata.language]
                      : [state.language]
                  }
                  labelField={"name"}
                  valueField={"slug"}
                  onChange={(values) =>
                    setState((prevState) => ({
                      ...prevState,
                      language: values[0],
                    }))
                  }
                  className="ReactDropdownSelect"
                />
              </div>
            </div>

            <div className="textfieldedituser">
              <label>{translate("subtitle")}</label>
              <span className="divider"></span>
              <input
                type="text"
                name="subtitle"
                value={state.subtitle}
                onChange={handleChange}
                required
              ></input>
            </div>
          </div>

         <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("duration")}</label>
              <span className="divider"></span>
              <input
                type="text"
                name="duration"
                value={state.duration}
                onChange={handleChange}
                required
              ></input>
            </div>

            <div className="genretags">
              <span className="genreimg">
                <p>{translate("courseStatus") + ": "}</p>
              </span>
              <div>
                <Select
                  options={courseStatusList}
                  labelField={"status_name"}
                  valueField={"id"}
                  values={
                    props.editdata && props.editdata.courseStatus
                      ? [props.editdata.courseStatus]
                      : courseStatus
                      ? [courseStatus]
                      : []
                  }
                  onChange={(values) => setCourseStatus(values[0])}
                  className="ReactDropdownSelect"
                />
              </div>
            </div>
          </div>

          <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("publicationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="publicationdate"
                value={state.publicationdate}
                onChange={handleChange}
                min={
                  props.editdata && props.editdata.publication_date
                    ? ""
                    : moment(new Date()).format("yyyy-MM-D")
                }
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("expirationdate")}</label>
              <span className="divider"></span>
              <input
                type="date"
                name="expirationdate"
                value={state.expirationdate}
                onChange={handleChange}
                min={moment(state.publicationdate).format("yyyy-MM-D")}
                required
              ></input>
            </div>
          </div>

        <div className="flexrows">
            <div className="textfieldedituser">
              <label>{translate("price")}</label>
              <span className="divider"></span>
              <input
                type="number"
                name="price"
                value={state.price}
                onChange={handleChange}
                required
              ></input>
            </div>

            <div className="textfieldedituser">
              <label>{translate("discount_price")}</label>
              <span className="divider"></span>
              <input
                type="number"
                name="discount_price"
                value={state.discount_price}
                onChange={handleChange}
                required
              ></input>
            </div>
          </div>

          <div className="flexrows">
            <div className="what_you_learn_container">
              <div className="what_you_learn_subcontainer">
                <div className="textfieldedituser">
                  <label>{translate("learn1")}</label>
                  <span className="divider"></span>
                  <input
                    placeholder="Learn1..."
                    value={state.learn1}
                    name="learn1"
                    onChange={handleChange}
                    required
                  ></input>
                </div>

                <div className="textfieldedituser">
                  <label>{translate("learn2")}</label>
                  <span className="divider"></span>
                  <input
                    placeholder="Learn2..."
                    value={state.learn2}
                    name="learn2"
                    onChange={handleChange}
                    required
                  ></input>
                </div>
              </div>

              <div className="what_you_learn_subcontainer">
                <div className="textfieldedituser">
                  <label>{translate("learn3")}</label>
                  <span className="divider"></span>
                  <input
                    placeholder="Learn3..."
                    value={state.learn3}
                    name="learn3"
                    onChange={handleChange}
                    required
                  ></input>
                </div>

                <div className="textfieldedituser">
                  <label>{translate("learn4")}</label>
                  <span className="divider"></span>
                  <input
                    placeholder="Learn4..."
                    value={state.learn4}
                    name="learn4"
                    onChange={handleChange}
                    required
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className="flexrows">
            <div className="checkbox-container">
              <div className="checkbox-content">
                <p>Show in shop ?</p>
                {state.inShop ? (
                  <>
                    <label className="container">
                      YES
                      <input
                        type="radio"
                        name="showShop"
                        value={true}
                        defaultChecked
                        onChange={(e) => {
                          setInShop(true);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container">
                      NO
                      <input
                        type="radio"
                        name="showShop"
                        value={false}
                        onChange={(e) => {
                          setInShop(false);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </>
                ) : (
                  <>
                    <label className="container">
                      YES
                      <input
                        type="radio"
                        name="showShop"
                        value={true}
                        onChange={(e) => {
                          setInShop(true);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container">
                      NO
                      <input
                        type="radio"
                        name="showShop"
                        value={false}
                        defaultChecked
                        onChange={(e) => {
                          setInShop(false);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </>
                )}
              </div>
              <div className="textfieldedituser">
                <label>{translate("satisfaction")}</label>
                <span className="divider"></span>
                <input
                  type="number"
                  name="satisfaction"
                  value={state.satisfaction}
                  onChange={handleChange}
                  required
                ></input>
              </div>
            </div>
          </div>

          <div className="flexrows">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="genretags">
                <span className="genreimg">
                  <p>{translate("level") + " "}</p>
                </span>
                <div className="dropdownselector">
                  <Select
                    options={levellist}
                    labelField={"name"}
                    valueField={"id"}
                    values={
                      props.editdata
                        ? [props.editdata.level]
                        : level
                        ? [level]
                        : []
                    }
                    onChange={(values) => {
                      values.length > 0 && setLevel(values[0]);
                    }}
                    className="ReactDropdownSelect"
                  />
                </div>
              </div>
            </div>
            <div className="addcourses genretags">
              <span className="genreimg">
                <p>{translate("course") + " "}</p>
              </span>
              <div className="dropdownselector">
                <Select
                  multi
                  options={courseslist}
                  labelField={"name"}
                  valueField={"id"}
                  values={
                    props.editdata && props.editdata.courses
                      ? props.editdata.courses
                      : courses
                  }
                  onChange={(values) => setCourses(values)}
                  className="ReactDropdownSelect"
                />
              </div>
            </div>
          </div> 
          <button className="uploadbutton" type="submit">
            {props.editdata ? translate("update") : translate("upload")}
          </button>
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default UploadBoundle;
