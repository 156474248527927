import React, { useState } from "react";
import GenericSelect from "../../../../../components/design-system/select";
import { putMethodAuthenticated } from "../../../../../utils/apis/services/api";
import urls from "../../../../../utils/apis/services/apiurls.json";
import { toast } from "react-toastify";
import checked from "../../../../../assets/images/checked.svg"

const ContestRow = ({
                        data,
                        downloadFile,
                        setShowModal,
                        onSetWinner,
                        statusList,
                        setNote,
                        noteList,
                        contestId,
                    }) => {
    const [backoffice_status, setBackofficeStatus] = useState(
        data.backoffice_status
    );
    const onSetStatus = async (value) => {
        const body = {
            backoffice_status: value.slug,
        };

        const result = await putMethodAuthenticated(
            urls.uploadcontests + contestId + "/applications/" + data.id + "/",
            body
        );

        if (result.data && !result.data.ErrorCode && result.status === 200) {
            toast.success("Status updated!");
            setBackofficeStatus(value);
        } else {
            toast.error("Error updating status!");
        }
    };

    const onNote = (id, note) => {
        setShowModal(true);
        const noteData = noteList.find((item) => item.id === id);
        if (noteData) {
            setNote({
                txt: noteData.txt,
                id: noteData.id,
            });
        } else setNote({ txt: note, id: id });
    };


    return (
        <tr>
            <td className=" rowdata1_contests_info rowdata">{data.user}</td>
            <td className=" rowdata2_contests_info rowdata">{data.location}</td>
            <td className=" rowdata3_contests_info rowdata">
                {(data.contest_musics.includes("soundcloud") ) ? (
                    <a
                        target="_blank"
                        className="soundcloud_link"
                        href={data.contest_musics}
                        rel="noreferrer"
                    >
                        soundcloud link
                    </a>
                ) : (
                    <button onClick={() => downloadFile(data.contest_musics)}>
                        download audio file
                    </button>
                )}
            </td>
            <td className=" rowdata4_contests_info rowdata">
                <button
                    onClick={() =>
                        onSetWinner(
                            data.id,
                            data.status,
                            data.backoffice_status,
                            data.backoffice_note
                        )
                    }
                >
                    {data.status === "winner" ? "delete winner" : "set winner"}
                </button>
            </td>
            <td className=" rowdata5_contests_info rowdata">
                <GenericSelect
                    options={statusList}
                    value={backoffice_status}
                    onSet={onSetStatus}
                    labelField={"name"}
                    valueField={"slug"}
                />
            </td>
            <td className=" rowdata6_contests_info rowdata">
                <button onClick={() => onNote(data.id, data.backoffice_note)} >
                    {
                        data.backoffice_note  && <img src={checked} alt="note-checked"/>
                    }
                    notes
                </button>
            </td>
            <td className=" rowdata7_contests_info rowdata">
                <p>{data.status}</p>
            </td>
        </tr>
    );
};

export default ContestRow;

