import React, { useState } from "react";
import urls from "../../../utils/apis/services/apiurls.json";
import { getMethod } from "../../../utils/apis/services/api";
import PartnerList from "./partnerList";
import PartnerForm from "./createpartnerform/partnerForm";
import "../uploadcontent/uploadcontent.scss";
import translate from "../../../utils/locale/language";
import LoadingOverlay from "react-loading-overlay";
import "./partner.scss";
import RoyaltiesTable from "../royalties/royaltiesTable";
import HistoryTable from "../royalties/history/historytable";

const Partner = () => {
  const [section, setSection] = useState(2);
  const [loader, setLoader] = useState(false);
  const [editData, setEditData] = useState();
  const [partnerId, setPartnerId] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  const EditUser = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.getallpartner + id + "/");
    setEditData(result.data);
    setSection(1);
    setLoader(false);
  };

  const getRoyaltiesData = async (id) => {
    setLoader(true);
    let result = await getMethod(urls.getallpartner + id + "/history/");
    setEditData(result.data);
    setPartnerId(id);
    setSection(3);
    setLoader(false);
  };

  const sectionCB = (value) => setSection(value);

  const goBack = (section) => {
    setEditData(null);
    setSection(section);
  };

  return (
    <div className="partner uploadcontent">
      <LoadingOverlay active={loader} spinner text={translate("loadertext")}>
        <div className=" userlisttable">
          {section === 1 && (
            <PartnerForm
              editData={editData}
              goBack={goBack}
              setEditData={setEditData}
            />
          )}
          {section === 2 && (
            <PartnerList
              EditUser={EditUser}
              setSection={setSection}
              section={section}
              getRoyaltiesData={getRoyaltiesData}
            />
          )}
          {section === 3 && (
            <RoyaltiesTable
              sectionCB={sectionCB}
              royalties={editData}
              setHistoryData={setHistoryData}
              type={"Partner"}
            />
          )}

          {section === 4 && (
            <HistoryTable
              sectionCB={sectionCB}
              tutorId={partnerId}
              historyData={historyData}
              type={"Partner"}
            />
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default Partner;
