import React from "react";

function Checkbox({title,state,onChange,name}) {
 
  return (
    <div className="checkbox-content">
      <p>{title}</p>
      <label className="container">
        YES
        <input
          type="radio"
          name={name}
          value={true}
          checked={state? true : null}
          onChange={(e) => {
            onChange(true);
          }}
        />
        <span className="checkmark"></span>
      </label>
      <label className="container">
        NO
        <input
          type="radio"
          name={name}
          value={false}
          checked={!state ? true : null}
          onChange={(e) => {
            onChange(false);
          }}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
}

export default Checkbox;
