import React,{useState} from 'react';
import "./reset-password.scss"
import InputText from "../../../components/design-system/inputText";
import {toast} from "react-toastify";
import {postMethodAuthenticated} from "../../../utils/apis/services/api";
import urls from "../../../utils/apis/services/apiurls.json";

const encodePassword = (password) => {
  return btoa(password);
};

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  );
};

const ResetPassword = () => {
  const [email,setEmail]=useState("")
  const[loader,setLoader]=useState(false)
  const handleMail=(e)=>setEmail(e.target.value)
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoader(true);

    const payload={
      email:email,
      password:encodePassword('@Ab12345')
    }

    try {
      let result = await postMethodAuthenticated(urls.resetpasswordusers, payload);
      toast.success(result.data.message);
    }
    catch(err) {
      toast.error(err);
    }
    finally {
      setEmail("")
      setLoader(false);
    }
  };

    return (
      <div className="reset-password-container uploadcontent">
        <h2>Reset Password</h2>
          <form className="uploadcourses form-reset-password" onSubmit={handleSubmit}>
            <InputText
              title="email"
              value={email}
              onChange={handleMail}
              placeholder={'user email'}
              name={"email"}
              type={"email"}
            />
            <button type={"submit"}>
              {loader? <Spinner/> : 'send email'}
            </button>
          </form>
      </div>
    );
};

export default ResetPassword;